import { FC, useMemo } from 'react';

import ChartDataProvider from './ChartData';

import BarChart from './ChartTypes/BarChart';
import BubbleChart from './ChartTypes/BubbleChart';
import AreaChart from './ChartTypes/AreaChart';
import ScatterChart from './ChartTypes/ScatterChart';
import LineChart from './ChartTypes/LineChart';
import PieChart from './ChartTypes/PieChart';
import RadarChart from './ChartTypes/RadarChart';
import StockChart from './ChartTypes/StockChart';
import SunburstChart from './ChartTypes/SunburstChart';
import FunnelChart from './ChartTypes/FunnelChart';
import BoxWhiskerChart from './ChartTypes/BoxWhiskerChart';
import ShapeFallback from '../ShapeFallback';
import { useSlideData } from '../../SlideData';

type ChartShapeProps = {
  shape: Presentation.Data.ChartShape;
};

const ChartShape: FC<ChartShapeProps> = ({ shape }) => {
  const { addUnsupportedElement } = useSlideData();
  //TODO:CHARTS Handle combo charts

  const chart = useMemo(() => {
    const chartTypes = shape.chartSpace.chart.plotArea.chartTypes;
    const chartexTypes = shape.chartSpace.chart.plotArea?.plotAreaRegion?.series?.[0]?.layoutId;

    if (!(shape.type === 'chartex' && shape.fallback.type === 'picture')) {
      chartTypes?.forEach((chart) => {
        if ('dropLines' in chart) {
          //TODO:PRESENTATION:UNSUPPORTED:CHART:DROPLINES
          addUnsupportedElement(`Chart - Lines - Drop Lines`);
        }
        if ('hilowLines' in chart && chart.type !== 'stock') {
          //TODO:PRESENTATION:UNSUPPORTED:CHART:HIGHLOWLINES
          addUnsupportedElement(`Chart - Lines - High Low Lines`);
        }

        if ('upDownBars' in chart && chart.type !== 'stock') {
          //TODO:PRESENTATION:UNSUPPORTED:CHART:UPDOWNBARS
          addUnsupportedElement(`Chart - Up/Down Bars`);
        }

        chart.ser?.forEach((serie) => {
          if ('errBars' in serie) {
            let errBars: Presentation.Data.ErrBars[] = [];

            if (Array.isArray(serie.errBars)) {
              errBars = [...serie.errBars];
            } else {
              errBars.push(serie.errBars);
            }

            errBars.forEach((err) => {
              let unsupportedErrValType = '';
              switch (err.errValType) {
                case 'cust':
                  unsupportedErrValType = 'Custom';
                  break;
                case 'fixedVal':
                  unsupportedErrValType = 'Fixed Value';
                  break;
                case 'percentage':
                  unsupportedErrValType = 'Percentage';
                  break;
                case 'stdDev':
                  unsupportedErrValType = 'Standard Deviation';
                  break;
                case 'stdErr':
                  unsupportedErrValType = 'Standard Error';
                  break;
              }
              //TODO:PRESENTATION:UNSUPPORTED:CHART:ERRBARS
              addUnsupportedElement(`Chart - Error Bars - ${unsupportedErrValType}`);
            });
          }

          if ('trendline' in serie) {
            serie.trendline.forEach((trendline) => {
              let unsupportedTrendline = '';
              switch (trendline.type) {
                case 'exp':
                  unsupportedTrendline = 'Exponential';
                  break;
                case 'linear':
                  unsupportedTrendline = 'Linear';
                  break;
                case 'poly':
                  unsupportedTrendline = 'Polynomial';
                  break;
                case 'power':
                  unsupportedTrendline = 'Power';
                  break;
                case 'movingAvg':
                  unsupportedTrendline = 'Moving Average';
                  break;
                case 'log':
                  unsupportedTrendline = 'Logarithmic';
                  break;
              }

              if (unsupportedTrendline) {
                //TODO:PRESENTATION:UNSUPPORTED:CHART:TRENDLINE
                addUnsupportedElement(`Chart - Trendline - ${unsupportedTrendline}`);
              }
            });
          }
        });
      });

      if (shape.chartSpace.chart.plotArea.dTable) {
        //TODO:PRESENTATION:UNSUPPORTED:CHART:DATATABLE
        addUnsupportedElement(`Chart - Data Table`);
      }
    }

    if (chartTypes?.find((chart) => chart?.type === 'stock')) {
      return <StockChart shape={shape} />;
    }

    if ((shape.chartSpace.chart.plotArea?.plotAreaRegion?.series.length ?? 0) > 1) {
      addUnsupportedElement(`Combo Chart`);
      //TODO:PRESENTATION:UNSUPPORTED
    }

    if (chartexTypes) {
      switch (chartexTypes) {
        case 'sunburst':
          return <SunburstChart shape={shape} />;
        case 'funnel':
          return <FunnelChart shape={shape} />;
        case 'boxWhisker':
          return <BoxWhiskerChart shape={shape} />;
        default: //If chart shape has image fallback, try to render chart as an image
          if (shape.type === 'chartex' && shape.fallback.type === 'picture') {
            addUnsupportedElement(`Chart - ${chartexTypes}`);
            //TODO:PRESENTATION:UNSUPPORTED
            return <ShapeFallback fallback={shape.fallback} />;
          }
      }
    }

    if ((chartTypes?.length ?? 0) > 1) {
      addUnsupportedElement(`Combo Chart`);
      //TODO:PRESENTATION:UNSUPPORTED
    }
    const chartElement = chartTypes?.[0];

    switch (chartElement?.type) {
      case 'bar':
        return <BarChart shape={shape} />;
      case 'bubble':
        if (chartElement.ser.find((ser) => ser.bubble3D)) {
          addUnsupportedElement(`Chart - ${chartElement?.type}3D`);
          //TODO:PRESENTATION:UNSUPPORTED
          return null;
        }
        return <BubbleChart shape={shape} />;
      case 'area':
        return <AreaChart shape={shape} />;
      case 'scatter':
        return <ScatterChart shape={shape} />;
      case 'line':
        return <LineChart shape={shape} />;
      case 'pie':
      case 'doughnut':
        return <PieChart shape={shape} />;
      case 'radar':
        return <RadarChart shape={shape} />;
      case 'area3D':
      case 'line3D':
      case 'pie3D':
      case 'bar3D':
      case 'surface3D':
      case 'surface':
        addUnsupportedElement(`Chart - ${chartElement?.type}`);
        //TODO:PRESENTATION:UNSUPPORTED
        return null;
      case 'ofPie':
        addUnsupportedElement(`Chart - ${chartElement.ofPieType}OfPie`);
        //TODO:PRESENTATION:UNSUPPORTED
        return null;
      default:
        return null;
    }
  }, [shape]);

  return <ChartDataProvider shape={shape}>{chart}</ChartDataProvider>;
};

export default ChartShape;
