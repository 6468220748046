import { CSSProperties, FC, useEffect, useMemo } from 'react';
import * as echarts from 'echarts';

import ShapeForeignObject from '../../ShapeForeignObject';
import EChartsWrapper from '../EChartsWrapper';
import ChartTitleShape from './TitleShape/TitleShape';
import ChartLegendShape from './LegendShape/LegendShape';
import Background from '../../Background';

import styles from './ChartBase.module.scss';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import { useUnsupportedProperties } from '../hooks';

type CharthapeProps = {
  shape: Presentation.Data.ChartShape;
  chartOptions: echarts.EChartsOption;
};

const ChartBase: FC<CharthapeProps> = ({ shape, chartOptions }) => {
  const { addUnsupportedElement } = useSlideData();
  const { handleUnsupportedShapeEffects } = useUnsupportedProperties();

  useEffect(() => {
    //TODO:PRESENTATION:UNSUPPORTED:CHART:AREA:EFFECTS
    handleUnsupportedShapeEffects(shape.chartSpace.properties, 'Chart Area');
  }, [shape]);

  const titleShape = useMemo<Presentation.Data.Shape | undefined>(() => {
    const { title } = shape.chartSpace.chart;

    if (!title) {
      return undefined;
    }

    const chartWidth = shape.properties.xfrm?.ext?.cx ?? 0;
    const chartHeight = shape.properties.xfrm?.ext?.cy ?? 0;

    let top: number = 0;
    let left: number = 0;

    if (title.overlay) {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:TITLE
      addUnsupportedElement('Chart - Title - Centered Overlay');
    }

    if (title.layout?.type === 'manual') {
      top = chartHeight * title.layout.y;
      left = chartWidth * title.layout.x;
    }

    const text = title.rich ? { ...title.rich } : { ...title.text };

    const firstChartType = shape.chartSpace.chart.plotArea.chartTypes?.[0];
    if (!text.childNodes?.[0].childNodes?.length) {
      text.childNodes?.[0].childNodes?.push({
        type: 'run',
        childNodes: [
          {
            type: 'text',
            content:
              (firstChartType?.ser.length ?? 0) > 1
                ? 'Chart Title'
                : firstChartType?.ser[0].tx?.strRef?.strCache.pt[0].v ?? '',
          },
        ],
        properties: {},
      });
    }

    return {
      type: 'shape',
      id: shape.id + '-chart-title',
      properties: {
        ...title.properties,
        xfrm: {
          off: {
            x: left,
            y: top,
          },
          ext: {
            cx: chartWidth,
            cy: chartHeight,
          },
        },
      },
      text,
    };
  }, [shape]);

  const legendShape = useMemo<Presentation.Data.Shape | undefined>(() => {
    const { legend } = shape.chartSpace.chart;

    if (!legend) {
      return undefined;
    }

    const legendWidthPct = legend.layout?.w;
    const legendHeightPct = legend.layout?.h;

    const chartWidth = shape.properties.xfrm?.ext?.cx ?? 0;
    const chartHeight = shape.properties.xfrm?.ext?.cy ?? 0;

    let top = 0;
    let left = 0;

    if (legend.layout?.type === 'manual') {
      top = chartHeight * legend.layout.y;
      left = chartWidth * legend.layout.x;
    }

    return {
      type: 'shape',
      id: shape.id + '-chart-legend',
      properties: {
        ...legend.properties,
        xfrm: {
          off: {
            x: left,
            y: top,
          },
          ext: {
            cx: legendWidthPct != null ? chartWidth * legendWidthPct : 0,
            cy: legendHeightPct != null ? chartHeight * legendHeightPct : 0,
          },
        },
      },
      text: legend.text,
    };
  }, [shape]);

  const chartSize = useMemo<{
    width: number | string | undefined;
    height: number | string | undefined;
  }>(() => {
    let width: number | string | undefined = undefined;
    let height: number | string | undefined = undefined;

    if (shape.chartSpace.chart.plotArea?.layout?.type === 'manual') {
      width = (shape.properties.xfrm?.ext?.cx ?? 0) * shape.chartSpace.chart.plotArea.layout.w;
      height = (shape.properties.xfrm?.ext?.cy ?? 0) * shape.chartSpace.chart.plotArea.layout.h;
    }

    return { width, height };
  }, [shape]);

  const order = useMemo<{ chart: number; title: number; legend: number }>(() => {
    const order: { chart: number; title: number; legend: number } = {
      chart: 0,
      title: 0,
      legend: 1,
    };

    const legend = shape.chartSpace.chart.legend;

    if (legend?.layout?.type === 'manual') {
      order.legend = 0;
    } else {
      switch (legend?.legendPos) {
        case 'l': {
          order.legend = 0;
          order.chart = 1;
          break;
        }
      }
    }

    return order;
  }, [shape]);

  const legendPosition = useMemo<'top' | 'right' | 'bottom' | 'left' | 'manual' | undefined>(() => {
    const legend = shape.chartSpace.chart.legend;
    //@ts-expect-error this isn't supported yet
    const position = legend?.legendPos ?? legend?.pos;

    if (!legend) {
      return undefined;
    }

    if (legend.layout?.type === 'manual') {
      return 'manual';
    }

    switch (position) {
      case 't': {
        return 'top';
      }
      case 'r':
      case 'tr': {
        return 'right';
      }
      case 'b': {
        return 'bottom';
      }
      case 'l': {
        return 'left';
      }
    }
  }, [shape]);

  const titleStyle = useMemo<CSSProperties>(() => {
    const title = shape.chartSpace.chart.title;

    const style: CSSProperties = { display: 'flex' };

    if (!title) {
      return style;
    }

    if (title.layout?.type === 'manual') {
      style.width = 'fit-content';
    } else {
      style.flexDirection = 'column';
      style.alignItems = 'center';
      style.order = order.title;
    }

    return style;
  }, [shape, order]);

  const legendStyle = useMemo<CSSProperties>(() => {
    const legend = shape.chartSpace.chart.legend;
    //@ts-expect-error this isn't supported yet
    const position = legend?.legendPos ?? legend?.pos;
    const style: CSSProperties = { display: 'flex' };

    if (!legend) {
      return style;
    }

    if (legend.layout?.type === 'manual') {
      style.width = 'fit-content';
    } else {
      switch (position) {
        case 't':
        case 'b': {
          style.flexDirection = 'column';
          break;
        }
      }

      style.alignItems = 'center';
      style.order = order.legend;
    }

    return style;
  }, [shape, order]);

  const renderLegend = () => {
    const legend = shape.chartSpace.chart.legend;
    if (!legend || !legendShape) {
      return null;
    }

    if (legend.layout?.type === 'manual') {
      return <ChartLegendShape shape={legendShape} chartShape={shape} />;
    }

    return (
      <div style={legendStyle}>
        <ChartLegendShape shape={legendShape} chartShape={shape} />
      </div>
    );
  };

  return (
    <g id={shape.id}>
      <Background
        position={{
          top: shape.properties.xfrm?.off?.y ?? 0,
          left: shape.properties.xfrm?.off?.x ?? 0,
        }}
        size={{
          width: shape.properties.xfrm?.ext?.cx ?? 0,
          height: shape.properties.xfrm?.ext?.cy ?? 0,
        }}
        fill={shape.chartSpace?.properties?.fill}
        outline={shape.chartSpace?.properties?.ln}
      />
      <ShapeForeignObject>
        <div className={styles.root}>
          {legendPosition === 'manual' && renderLegend()}
          <div className={styles.top}>
            {titleShape && (
              <div style={titleStyle}>
                <ChartTitleShape shape={titleShape} />
              </div>
            )}

            {legendPosition === 'top' && renderLegend()}
          </div>
          <div className={styles.middle}>
            {(legendPosition === 'right' || legendPosition === 'left') && renderLegend()}
            <EChartsWrapper
              style={{
                height: '100%',
                width: '100%',
                order: order.chart,
              }}
              options={{
                width: chartSize.width,
                height: chartSize.height,
                animation: false,
                title: {
                  show: false,
                },
                ...chartOptions,
              }}
            />
          </div>
          <div className={styles.bottom}>{legendPosition === 'bottom' && renderLegend()}</div>
        </div>
      </ShapeForeignObject>
    </g>
  );
};

export default ChartBase;
