import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import useOutline from '../../useOutline';

const useUnsupportedProperties = () => {
  const { addUnsupportedElement } = useSlideData();
  const { parseOutline } = useOutline();

  const handleTextFill = (fill: Presentation.Data.Common.FillType | undefined) => {
    if (!fill) {
      return;
    }

    switch (fill.type) {
      case 'none':
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:NONE
        addUnsupportedElement('Chart - Text - No Fill');
        break;
      case 'gradient':
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:GRADIENT
        addUnsupportedElement('Chart - Text - Gradient Fill');
        break;
      case 'picture':
        fill.tile
          ? //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:TEXTURE
            addUnsupportedElement('Chart - Text - Texture Fill')
          : //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:PICTURE
            addUnsupportedElement('Chart - Text - Picture Fill');
        break;
      case 'pattern':
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:FILL:PATTERN
        addUnsupportedElement('Chart - Text - Pattern Fill');
        break;
    }
  };

  const handleTextOutline = (ln: Presentation.Data.Outline | undefined) => {
    if (!ln) {
      return;
    }

    switch (ln?.fill?.type) {
      case 'gradient':
        //TODO:PRESENTATION:UNSUPPORTED:TEXT:OUTLINE:GRADIENT:FILL
        addUnsupportedElement(`Chart - Text - Gradient Line`);
        break;
    }

    parseOutline(ln);
  };

  const handleUnsupportedShapeEffects = (
    properties: Presentation.Data.ShapeProperties | undefined,
    target = 'Chart',
  ) => {
    if (!properties) {
      return;
    }

    properties.effects?.forEach((effect) => {
      addUnsupportedElement(`${target} - Effect - ${effect.type}`);
    });

    if (properties.sp3d?.bevelT || properties.sp3d?.bevelB) {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:EFFECTS
      addUnsupportedElement(`${target} - Effect - Bevel`);
    }

    if (properties.sp3d?.prstMaterial) {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:EFFECTS
      addUnsupportedElement(`${target} - Effect - Material`);
    }

    if (properties.scene3d?.camera.prst && properties.scene3d.camera.prst !== 'orthographicFront') {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:EFFECTS
      addUnsupportedElement(`${target} - Effect - Perspective`);
    }
  };

  const handleUnsupportedText = (text: Presentation.Data.TextBody | undefined) => {
    if (!text?.bodyPr) {
      return;
    }

    if (text?.bodyPr?.vert === 'wordArtVert' || text?.bodyPr?.vert === 'wordArtVertRtl') {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:AXIS
      addUnsupportedElement('Chart - Axis Text Direction - Stacked');
    }

    if (text.bodyPr.wrap === 'square' && text.bodyPr.prstTxWarp) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT:EFFECTS
      addUnsupportedElement(`Chart - Text Effect - Transform`);
    }

    if (text.bodyPr.tx3d?.sp3d?.bevelT || text.bodyPr.tx3d?.sp3d?.bevelB) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT:EFFECTS
      addUnsupportedElement(`Chart - Text Effect - Bevel`);
    }

    if (
      text.bodyPr.scene3d?.camera.prst &&
      text.bodyPr.scene3d.camera.prst !== 'orthographicFront'
    ) {
      //TODO:PRESENTATION:UNSUPPORTED:TEXT:EFFECTS
      addUnsupportedElement(`Chart - Text Effect - Perspective`);
    }

    text.childNodes?.forEach((paragraph) => {
      handleTextFill(paragraph.properties.inlineProperties?.fill);
      handleTextOutline(paragraph.properties?.inlineProperties?.ln);

      paragraph.properties.inlineProperties?.effects?.forEach((effect) => {
        addUnsupportedElement(`Chart - Text Effect - ${effect.type}`);
      });

      paragraph.childNodes?.forEach((run) => {
        handleTextFill(run.properties?.fill);
        handleTextOutline(run.properties?.ln);

        run.properties?.effects?.forEach((effect) => {
          addUnsupportedElement(`Chart - Text Effect - ${effect.type}`);
        });
      });
    });
  };

  return { handleUnsupportedShapeEffects, handleUnsupportedText };
};

export default useUnsupportedProperties;
