import { useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { useSlideData } from '../SlideData';
import { useShapeData } from './ShapeData';
import useGeometry from './useGeometry';
import useOutline from './useOutline';

import { modifyColor } from './Preset/utils';
import Pattern from '../Background/Pattern';
import Image from './Image';
import GradientDefinition from '../GradientDefinition';

type BackgroundProps = {
  fill?: Presentation.Data.Common.FillType;
  geometry?: Presentation.Shape.Geometry;
  xfrm?: Presentation.Shape.Xfrm;
  outline?: Presentation.Data.Outline;
  position: Presentation.Data.Common.Position;
  size: Presentation.Data.Common.Size;
};

/**
 *
 * TODO:PRESENTATION
 * use this Background component to render the background
 * src/Presentation/Slides/Slide/Background/Background.tsx
 */

const Background = ({ fill, geometry, xfrm, outline, position, size }: BackgroundProps) => {
  const { color, addUnsupportedElement } = useSlideData();
  const { shape } = useShapeData();

  const { paths } = useGeometry(geometry, size, position, shape.properties);
  const { parseOutline } = useOutline();
  const outlineProps = parseOutline(outline);

  const shapeType = useMemo(() => {
    switch (shape.type) {
      case 'chart':
      case 'chartex':
        return 'Chart';
      case 'picture':
        return 'Picture';
      case 'table':
        return 'Table';
      default:
        return 'Shape';
    }
  }, [shape]);

  //Avoid generating a new id on every rerender
  const bgId = useMemo(() => uuid(), []);
  const outlineId = useMemo(() => uuid(), []);
  const ellipseId = useMemo(() => uuid(), []);
  const ellipsePathId = useMemo(() => uuid(), []);

  //Process Shape properties to detect unsupported properties
  useEffect(() => {
    //TODO:PRESENTATION:UNSUPPORTED:SHAPE:EFFECTS
    shape.properties.effects?.forEach((effect) => {
      addUnsupportedElement(`${shapeType} Effect - ${effect.type}`);
    });

    if (shape.properties.sp3d?.bevelT || shape.properties.sp3d?.bevelB) {
      //TODO:PRESENTATION:UNSUPPORTED:SHAPE:EFFECTS
      addUnsupportedElement(`${shapeType} Effect - Bevel`);
    }

    if (shape.properties.sp3d?.prstMaterial) {
      //TODO:PRESENTATION:UNSUPPORTED:SHAPE:EFFECTS
      addUnsupportedElement(`${shapeType} Effect - Material`);
    }

    if (
      shape.properties.scene3d?.camera.prst &&
      shape.properties.scene3d.camera.prst !== 'orthographicFront'
    ) {
      //TODO:PRESENTATION:UNSUPPORTED:SHAPE:EFFECTS
      addUnsupportedElement(`${shapeType} Effect - Perspective`);
    }

    //Process Picture specific properties to detect unsupported properties
    if (shape.type === 'picture') {
      const recolorEffects: Presentation.Data.Effect['type'][] = [
        'grayscale',
        'duotone',
        'lum',
        'biLevel',
      ];

      //TODO:PRESENTATION:UNSUPPORTED:PICTURE:RECOLOR
      if (shape.fill.effects?.some((effect) => recolorEffects.includes(effect.type))) {
        addUnsupportedElement(`Picture Effect - Recolor`);
      }

      //TODO:PRESENTATION:UNSUPPORTED:PICTURE:CROPTOSHAPE
      if (
        shape.properties.geom?.type === 'custom' ||
        (shape.properties.geom?.type === 'prst' && shape.properties.geom.prst !== 'rect')
      ) {
        addUnsupportedElement(`Picture Effect - Crop to Shape`);
      }
    }
  }, [shape]);

  const renderFillDef = (fill: Presentation.Data.Common.FillType | undefined, id: string) => {
    if (fill) {
      switch (fill.type) {
        case 'solid':
          return (
            <pattern id={id} width="1" height="1" patternUnits="userSpaceOnUse">
              <rect width="1" height="1" fill={color(fill.color)} />
            </pattern>
          );
        case 'gradient':
          return <GradientDefinition id={id} gradientFill={fill} forShape />;
        case 'pattern':
          return (
            <Pattern
              id={id}
              pattern={fill.preset}
              backgroundColor={color(fill.background)}
              foregroundColor={color(fill.foreground)}
            />
          );
        case 'picture': {
          if (fill.tile) {
            //TODO:PRESENTATION:UNSUPPORTED:BACKGROUND:FILL
            addUnsupportedElement(`${shapeType} - Fill - Texture`);
          } else {
            //TODO:PRESENTATION:UNSUPPORTED:BACKGROUND:PICTURE
            addUnsupportedElement(`${shapeType} - Fill - Picture`);
          }
          return null;
        }
      }
    }
    return null;
  };

  return (
    <>
      <g id={`${shape.id}-background`} transform={`translate(${position.left}, ${position.top})`}>
        <defs>
          {renderFillDef(fill, bgId)}
          {renderFillDef(outline?.fill, outlineId)}
        </defs>
        <g
          //This is responsible for the rotation and flip of shapes
          style={{
            transformOrigin: 'center center',
            transform: `
              rotate(${xfrm?.rot ?? 0}deg) 
              scale(${xfrm?.flipH ? -1 : 1}, ${xfrm?.flipV ? -1 : 1})
            `,
            transformBox: 'fill-box',
          }}
        >
          {paths
            //Sort so stroke paths are on top of fill paths
            .sort((a) => (a.stroke === 'false' ? -1 : 1))
            .map(({ fillModifier, custom, ...shapeProps }) => {
              const { stroke, ...strokeProps } = outlineProps;
              const modifiedFill =
                fill?.type === 'solid' && fillModifier
                  ? modifyColor(color(fill.color), fillModifier)
                  : undefined;

              const renderStroke = shapeProps.stroke !== 'false';
              const renderFill = shapeProps.fill !== 'none';

              switch (custom?.type) {
                case 'line':
                  return (
                    <line
                      key={`${shapeProps.d}-${shapeProps.fill ? 'fill' : 'nofill'}-${
                        renderStroke ? 'stroke' : 'nostroke'
                      }`}
                      x1={custom.x1}
                      y1={custom.y1}
                      /**
                       * By default, gradientUnits is objectBoundingBox
                       * This should not be used when the geometry of the applicable element has no width or no height,
                       * such as the case of a horizontal or vertical line
                       * Add low offset to avoid perfect horizontal/vertical line
                       * Source: https://stackoverflow.com/a/73043947
                       */
                      x2={custom.x2 + 0.001}
                      y2={custom.y2 + 0.001}
                      {...shapeProps}
                      {...strokeProps}
                      stroke={renderStroke ? `url(#${outlineId})` : 'false'}
                    />
                  );
                default:
                  return (
                    <path
                      key={`${shapeProps.d}-${shapeProps.fill ? 'fill' : 'nofill'}-${
                        renderStroke ? 'stroke' : 'nostroke'
                      }`}
                      {...shapeProps}
                      {...strokeProps}
                      stroke={renderStroke ? `url(#${outlineId})` : 'false'}
                      fill={renderFill ? modifiedFill || `url(#${bgId})` : 'none'}
                    />
                  );
              }
            })}
        </g>
      </g>
      {shape.type === 'picture' && (
        <g id={`${shape.id}-picture`} transform={`translate(${position.left}, ${position.top})`}>
          <g
            //This is responsible for the rotation and flip of images
            style={{
              transformOrigin: 'center center',
              transform: `
              rotate(${xfrm?.rot ?? 0}deg) 
              scale(${xfrm?.flipH ? -1 : 1}, ${xfrm?.flipV ? -1 : 1})
            `,
              transformBox: 'fill-box',
            }}
          >
            {paths.map(({ fillModifier, custom, ...shapeProps }) => {
              return (
                <>
                  {!shape.fill.effects && (
                    <path
                      key={shapeProps.d}
                      x={position.left}
                      y={position.top}
                      {...shapeProps}
                      {...outline}
                      fill="transparent"
                      stroke="none"
                      id={ellipsePathId}
                    />
                  )}
                  <clipPath id={ellipseId}>
                    <use xlinkHref={`#${ellipsePathId}`} />
                  </clipPath>
                  {shape.fill.type === 'picture' && (
                    <Image
                      fill={shape.fill}
                      clip={
                        shape.nvProperties?.userDrawn ||
                        !shape.nvProperties?.ph?.type ||
                        shape.nvProperties?.ph?.sz === 'quarter'
                          ? 'none'
                          : `url(#${ellipseId})`
                      }
                    />
                  )}
                </>
              );
            })}
          </g>
        </g>
      )}
    </>
  );
};

export default Background;
