import ChartBase from '../ChartBase/ChartBase';
import {
  useChartAxis,
  useChartColor,
  useChartOutline,
  useChartPlotArea,
  useDataLabel,
  useStacked100,
} from '../hooks';

const useSeries = ({
  shape,
}: {
  shape: Presentation.Data.ChartShape;
}): echarts.LineSeriesOption[] | undefined => {
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { dataLabel } = useDataLabel();
  const { stacked100 } = useStacked100();

  if (shape.chartSpace.chart.plotArea.chartTypes?.[0].type !== 'area') {
    return undefined;
  }

  const chartTypeData: Presentation.Data.AreaChart = shape.chartSpace.chart.plotArea.chartTypes[0];

  const seriesInfo = chartTypeData.ser.map((el) => {
    if (el.val?.numRef?.numCache?.pt) {
      return el.val?.numRef?.numCache?.pt?.map((value) => +value.v * 10);
    }
    return [0];
  });

  const stack = chartTypeData.grouping === 'stacked';
  const stack100Percentage = chartTypeData.grouping === 'percentStacked';
  const percentageData = stacked100(seriesInfo);

  let anyLabel = false;

  const series = chartTypeData.ser.map((serie, i) => {
    let hasLabel = false;

    const lineOutline = chartOutline(serie.properties?.ln);

    const option: echarts.LineSeriesOption = {
      type: 'line',
      silent: true,
      name: serie.tx?.strRef?.strCache?.pt[0].v,
      id: serie.idx,
      symbolSize: 0,
      lineStyle: {
        //@ts-expect-error CHARTS:LIMITATION doenst support picture
        color: lineOutline?.borderColor,
        cap: lineOutline?.borderCap,
        join: lineOutline?.borderJoin,
        miterLimit: lineOutline?.borderMiterLimit,
        type: lineOutline?.borderType,
        width: lineOutline?.borderWidth,
        opacity: lineOutline?.borderColor == null ? 0 : undefined,
      },
      areaStyle: {
        //@ts-expect-error CHARTS:LIMITATION doenst support picture
        color:
          serie.properties?.fill?.type === 'none'
            ? 'transparent'
            : chartColor(serie?.properties?.fill),

        opacity: 1,
      },
      stack: stack || stack100Percentage ? 'stack' : undefined,
      data: serie.val?.numRef?.numCache.pt.map((numVal, j) => {
        const idx = numVal.idx;

        const cat =
          serie.cat?.strRef?.strCache?.pt?.find((pt) => pt.idx === idx) ??
          serie.cat?.numRef?.numCache?.pt?.find((pt) => pt.idx === idx) ??
          serie.cat?.strLit?.pt?.find((pt) => pt.idx === idx) ??
          serie.cat?.numLit?.pt?.find((pt) => pt.idx === idx);

        const label = dataLabel({
          dLbls: serie.dLbls,
          val: numVal.v,
          cat: cat?.v,
          idx,
          defaultPosition: 'bottom',
          chart: chartTypeData,
        });
        if (label?.show) {
          hasLabel = true;
          anyLabel = true;
        }
        return {
          value: stack100Percentage ? percentageData[i][j] : numVal.v,
          label: {
            ...label,
          },
        };
      }),
      //@ts-expect-error "symbol" cant be null, but this is a hack to display data labels without symbol.
      symbol: hasLabel ? null : 'none',
      z: i,
    };

    return option;
  });

  if (anyLabel) {
    return series.map((serie) => {
      return { ...serie, z: undefined };
    });
  }

  return series;
};

const AreaChart = ({ shape }: { shape: Presentation.Data.ChartShape }) => {
  const axis = useChartAxis(shape);
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea);
  const series = useSeries({ shape });

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        //@ts-expect-error echarts types
        grid: {
          ...grid,
        },
        xAxis: axis.xAxis,
        yAxis: axis.yAxis,
        series,
      }}
    />
  );
};

export default AreaChart;
