import { useChartColor, useChartOutline } from '../hooks';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';

export const defaultColors = [
  {
    properties: {
      fill: { color: { base: '4472C4' }, type: 'solid' },
    },
  },
  {
    properties: {
      fill: {
        color: { base: 'ED7D31' },
        type: 'solid',
      },
    },
  },
  {
    properties: {
      fill: { color: { base: 'A5A5A5' }, type: 'solid' },
    },
  },
];

export const defaultBprderColors = [
  {
    properties: {
      fill: {
        type: 'solid',
        color: {
          mods: [
            {
              type: 'lumMod',
              val: 75,
            },
          ],
          reference: 'accent1',
        },
      },
    },
  },
  {
    properties: {
      fill: {
        type: 'solid',
        color: {
          mods: [
            {
              type: 'lumMod',
              val: 75,
            },
          ],
          reference: 'accent2',
        },
      },
    },
  },
  {
    properties: {
      fill: {
        type: 'solid',
        color: {
          mods: [
            {
              type: 'lumMod',
              val: 50,
            },
          ],
          reference: 'bg2',
        },
      },
    },
  },
];

const useBoxWhiskersSeries = () => {
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { addUnsupportedElement } = useSlideData();

  const boxWhiskersSeries = ({
    chart,
  }: {
    //@ts-expect-error this isn't supported yet
    chart: Presentation.Data.BoxWhiskersChart;
  }): echarts.BoxplotSeriesOption[] => {
    const uniqueNames: string[] = [];

    chart.chartData?.data[0].dim[0].lvl[0].pt.filter((item: { content: string }) => {
      if (!uniqueNames.includes(item.content)) {
        uniqueNames.push(item.content);
        return true;
      }
      return false;
    });

    const calculateMeans = (array: number[]) => {
      var sum = array.reduce(function (a, b) {
        return a + b;
      }, 0);
      return sum / array.length;
    };

    const means = () => {
      return chart.chartData.data.map(
        (value: { dim: { lvl: { pt: { [x: string]: { content: string } } }[] }[] }) => {
          return uniqueNames.map((val) => {
            let array: number[] = [];
            //@ts-expect-error
            value.dim[1].lvl[0].pt.map((el: { content: string }, index: string | number) => {
              if (value.dim[0].lvl[0].pt[index].content === val) {
                array.push(Number(el.content));
              }
              return null;
            });

            return calculateMeans(array);
          });
        },
      );
    };

    const calculateValues = (array: number[]) => {
      const sortedArray = array.slice().sort((a, b) => a - b);
      const size = sortedArray.length;
      const medium = size / 2;
      const min = Math.min(...sortedArray);
      const max = Math.max(...sortedArray);
      const Q1 =
        size % 2 === 0
          ? (sortedArray[0] + sortedArray[1]) / 2
          : size === 7
          ? sortedArray[1]
          : (sortedArray[1] + sortedArray[2]) / 2;
      const Q2 =
        size % 2 === 0
          ? (sortedArray[medium - 1] + sortedArray[medium]) / 2
          : sortedArray[Number.isInteger(medium) ? medium : medium - 0.5];

      const q3Value = () => {
        if (size % 2 === 0) {
          return (sortedArray[size - 2] + sortedArray[size - 1]) / 2;
        } else {
          if (size === 7) {
            return sortedArray[size - 2];
          } else {
            return (sortedArray[size - 3] + sortedArray[size - 2]) / 2;
          }
        }
      };

      const Q3 = q3Value();
      return [min, Q1, Q2, Q3, max];
    };

    const handleCategoriesValues = () => {
      //@ts-expect-error this isn't supported yet
      return chart.chartData.data.map((value) => {
        return uniqueNames.map((val) => {
          let array: number[] = [];
          value.dim[1].lvl[0].pt.map((el: { content: string }, index: string | number) => {
            if (value.dim[0].lvl[0].pt[index].content === val) {
              array.push(Number(el.content));
            }
            return null;
          });

          if (
            chart.chart.plotArea.plotAreaRegion.series[0].layoutPr.statistics.quartileMethod ===
            'exclusive'
          ) {
            return calculateValues(array);
          } else return array;
        });
      });
    };

    if (chart.chart.plotArea.plotAreaRegion.series[0].layoutId === 'boxWhisker') {
      const boxWhiskerSerie = chart.chart.plotArea.plotAreaRegion
        .series[0] as Presentation.Data.BoxWhiskerSerie;

      if (Object.values(boxWhiskerSerie.dataLabels.visibility).some(Boolean)) {
        //TODO:PRESENTATION:UNSUPPORTED:CHART:BOX&WHISKER:LABELS
        addUnsupportedElement('Chart - Box & Whisker - Labels');
      }

      Object.entries(boxWhiskerSerie.layoutPr.visibility)
        .filter(([_, value]) => value)
        .forEach(([key]) => {
          const typedKey = key as keyof Presentation.Data.LayoutProperties['visibility'];
          switch (typedKey) {
            case 'nonoutliers':
              //TODO:PRESENTATION:UNSUPPORTED:CHART:BOX&WHISKER:SERIESOPTIONS:INNERPOINTS
              addUnsupportedElement(`Chart - Box & Whisker - Show Inner Points`);
              return;
            case 'outliers':
              //TODO:PRESENTATION:UNSUPPORTED:CHART:BOX&WHISKER:SERIESOPTIONS:OUTLIERPOINTS
              addUnsupportedElement(`Chart - Box & Whisker - Show Outlier Points`);
              return;
            default:
              //TODO:PRESENTATION:UNSUPPORTED:CHART:BOX&WHISKER:SERIESOPTIONS
              addUnsupportedElement(`Chart - Box & Whisker - Show ${typedKey}`);
          }
        });
    }

    return chart.chartData?.data.map((_: any, index: number) => {
      const outline = chartOutline(
        chart.chart.plotArea.plotAreaRegion.series[index]?.properties?.ln,
      );
      const option: echarts.BoxplotSeriesOption = {
        type: 'boxplot',
        id: index,
        silent: true,
        itemStyle: {
          ...outline,
          //@ts-expect-error echarts type
          borderColor:
            outline?.borderColor ??
            //@ts-expect-error color only supports string (solid color)
            chartColor(defaultBprderColors[index]?.properties?.fill),
          //@ts-expect-error color only supports string (solid color)
          color: chartColor(
            chart.chart.plotArea.plotAreaRegion.series[index]?.properties?.fill ??
              defaultColors[index]?.properties?.fill,
          ),
        },
        data: handleCategoriesValues()[index],
        boxWidth: [7, 72],
        markPoint: {
          symbol:
            chart.chartData?.data.length > 1 ||
            !chart.chart.plotArea.plotAreaRegion.series[0].layoutPr.visibility.meanMarker
              ? 'none'
              : 'path://M6.343 5.657l-1.414 1.414L10.586 12l-5.657 5.657 1.414 1.414L12 13.414l5.657 5.657 1.414-1.414L13.414 12l5.657-5.657-1.414-1.414L12 10.586z',
          symbolSize: [10, 10],
          data: means()[index].map(function (value: number, index1: number) {
            return { coord: [index1, value], name: 'Mean' };
          }),
          itemStyle: {
            //@ts-expect-error color only supports string (solid color)
            color:
              outline?.borderColor ??
              //@ts-expect-error color only supports string (solid color)
              chartColor(defaultBprderColors[index]?.properties?.fill),
          },
        },
      };

      return option;
    });
  };

  return { boxWhiskersSeries };
};

export default useBoxWhiskersSeries;
