import { FC, useMemo } from 'react';
import useOutline from '../useOutline';

type TableCellBorder = {
  ln: Presentation.Data.Outline;
  placement: 'T' | 'R' | 'B' | 'L' | 'BlToTr' | 'TlToBr';
};

const TableCellBorder: FC<TableCellBorder> = ({ ln, placement }) => {
  const { parseOutline } = useOutline();
  const outlineProps = parseOutline(ln);

  const props = useMemo(() => {
    switch (placement) {
      case 'T':
        return { x1: 0, y1: 0, x2: '100%', y2: 0 };
      case 'R':
        return { x1: '100%', y1: 0, x2: '100%', y2: '100%' };
      case 'B':
        return { x1: 0, y1: '100%', x2: '100%', y2: '100%' };
      case 'L':
        return { x1: 0, y1: 0, x2: 0, y2: '100%' };
      case 'TlToBr':
        return { x1: 0, y1: 0, x2: '100%', y2: '100%' };
      case 'BlToTr':
        return { x1: 0, y1: '100%', x2: '100%', y2: 0 };
    }
  }, [ln, placement]);

  return <line {...props} {...outlineProps} />;
};

export default TableCellBorder;
