import { FC, useLayoutEffect, useRef, useState } from 'react';
import { useTableData } from './TableData';
import Background from '../Background';
import TableCellBorder from './TableCellBorder';
import styles from './TableShape.module.scss';
import TextBody from '../TextBody/TextBody';
import useStyle from './useStyle';
import { useSelector } from '_common/hooks';

const NO_POSITION = { top: 0, left: 0 };

type TableCellProps = {
  tc: Presentation.Data.Table.Cell;
  tblStyle: Presentation.Data.Table.Style | null;
  rowIndex: number;
  colIndex: number;
};

const TableCell: FC<TableCellProps> = ({ tc, tblStyle, rowIndex, colIndex }) => {
  const ref = useRef<HTMLTableCellElement | null>(null);
  const { positions } = useTableData();
  const [bounds, setBounds] = useState<DOMRect | null>(null);
  const zoom = useSelector((state) => state.presentation.general.zoom);

  useLayoutEffect(() => {
    if (ref.current) {
      setBounds(ref.current.getBoundingClientRect());
    }
  }, [zoom]);

  const pos = positions[rowIndex][colIndex];
  const { style, getTextStyle } = useStyle(tblStyle?.[pos.placement], tc.tcPr);
  const textStyle = getTextStyle(tblStyle?.[pos.placement]?.tcTxStyle);
  const width = bounds?.width ?? 0;
  const height = bounds?.height ?? 0;

  const fill =
    tc.tcPr.fill ||
    tblStyle?.[pos.placement]?.tcStyle?.themeableFill?.fill ||
    tblStyle?.wholeTbl?.tcStyle?.themeableFill?.fill;
  const lnT =
    tc.tcPr.lnT ||
    tblStyle?.[pos.placement]?.tcStyle?.tcBdr?.t?.ln ||
    tblStyle?.wholeTbl?.tcStyle?.tcBdr?.t?.ln;
  const lnR =
    tc.tcPr.lnR ||
    tblStyle?.[pos.placement]?.tcStyle?.tcBdr?.r?.ln ||
    tblStyle?.wholeTbl?.tcStyle?.tcBdr?.r?.ln;
  const lnB =
    tc.tcPr.lnB ||
    tblStyle?.[pos.placement]?.tcStyle?.tcBdr?.b?.ln ||
    tblStyle?.wholeTbl?.tcStyle?.tcBdr?.b?.ln;
  const lnL =
    tc.tcPr.lnL ||
    tblStyle?.[pos.placement]?.tcStyle?.tcBdr?.l?.ln ||
    tblStyle?.wholeTbl?.tcStyle?.tcBdr?.l?.ln;
  const lnTlToBr =
    tc.tcPr.lnTlToBr ||
    tblStyle?.[pos.placement]?.tcStyle?.tcBdr?.tl2br?.ln ||
    tblStyle?.wholeTbl?.tcStyle?.tcBdr?.tl2br?.ln;
  const lnBlToTr =
    tc.tcPr.lnBlToTr ||
    tblStyle?.[pos.placement]?.tcStyle?.tcBdr?.tr2bl?.ln ||
    tblStyle?.wholeTbl?.tcStyle?.tcBdr?.tr2bl?.ln;

  return (
    <td
      key={tc.id}
      style={style}
      className={styles.td}
      rowSpan={tc.rowSpan ?? 1}
      colSpan={tc.gridSpan ?? 1}
      ref={ref}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width={width / zoom ?? '100%'}
        height={height / zoom ?? '100%'}
      >
        <Background
          position={NO_POSITION}
          size={{ ...bounds, width: width / zoom, height: height / zoom } ?? pos}
          fill={fill}
        />
        {lnT && <TableCellBorder ln={lnT} placement="T" />}
        {lnR && <TableCellBorder ln={lnR} placement="R" />}
        {lnB && <TableCellBorder ln={lnB} placement="B" />}
        {lnL && <TableCellBorder ln={lnL} placement="L" />}
        {lnTlToBr && <TableCellBorder ln={lnTlToBr} placement="TlToBr" />}
        {lnBlToTr && <TableCellBorder ln={lnBlToTr} placement="BlToTr" />}
      </svg>
      {tc.text && (
        <foreignObject>
          <TextBody text={tc.text} textStyle={textStyle} />
        </foreignObject>
      )}
    </td>
  );
};

export default TableCell;
