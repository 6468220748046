import { OUTLINE_CAP, OUTLINE_DASHARRAY } from 'Presentation/consts';
import { useSlideData } from '../SlideData';
import { useShapeData } from './ShapeData';
import { useMemo } from 'react';

const dashArray = ({
  dash,
  width,
}: {
  dash: Presentation.Data.Outline['dash'];
  width: number | undefined;
}) => {
  const dashArray = OUTLINE_DASHARRAY[dash ?? 'solid'];
  const strokeWidth = width ?? 1; //px

  if (!Array.isArray(dashArray)) {
    return dashArray;
  }

  return dashArray.map((value) => value * strokeWidth).toString();
};

const useOutline = () => {
  const { color, addUnsupportedElement } = useSlideData();
  const { shape } = useShapeData();

  const shapeType = useMemo(() => {
    switch (shape.type) {
      case 'chart':
      case 'chartex':
        return 'Chart';
      case 'picture':
        return 'Picture';
      case 'table':
        return 'Table';
      default:
        return 'Shape';
    }
  }, [shape]);

  const parseOutline = (ln: Presentation.Data.Outline | undefined) => {
    if (ln) {
      const strokeWidth = ln.w ?? 1;
      const c = ln?.fill?.type === 'solid' ? ln.fill.color : ln.fill?.stops?.[0]?.color;
      const strokeDasharray = dashArray({ dash: ln.dash, width: ln.w });
      const strokeLinejoin = ln.join?.type;
      const strokeLinecap = ln.cap ? OUTLINE_CAP[ln.cap] : undefined; // for some reason they use flat for the 'butt' stroke-linecap attribute
      const strokeMiterlimit = ln.join?.type === 'miter' ? ln.join.lim : undefined;

      let unsupportedCmpd = '';

      switch (ln.cmpd) {
        case 'dbl':
          unsupportedCmpd = 'Double';
          break;
        case 'thickThin':
          unsupportedCmpd = 'Thick thin';
          break;
        case 'thinThick':
          unsupportedCmpd = 'Thin thick';
          break;
        case 'tri':
          unsupportedCmpd = 'Triple';
          break;
      }

      if (unsupportedCmpd) {
        //TODO:PRESENTATION:UNSUPPORTED:OUTLINE:COMPOUND
        addUnsupportedElement(`${shapeType} - Line Compound type - ${unsupportedCmpd}`);
      }

      //Warning won't appear if property is undefined or 'none'
      if (ln.headEnd && ln.headEnd.type !== 'none') {
        //TODO:PRESENTATION:UNSUPPORTED:OUTLINE:ARROW (rework to use markers)
        addUnsupportedElement(`${shapeType} - Line - Begin arrow`);
      }

      //Warning won't appear if property is undefined or 'none'
      if (ln.tailEnd && ln.tailEnd.type !== 'none') {
        //TODO:PRESENTATION:UNSUPPORTED:OUTLINE:ARROW (rework to use markers)
        addUnsupportedElement(`${shapeType} - Line - End arrow`);
      }

      if (c) {
        return {
          strokeWidth,
          stroke: color(c),
          strokeDasharray,
          strokeLinejoin,
          strokeLinecap,
          strokeMiterlimit,
        }; // missing other stroke attributes
      }
    }
    return {};
  };

  return { parseOutline };
};

export default useOutline;
