import { useMemo } from 'react';
import { useSelector } from '_common/hooks';

type TextChildProps =
  | { text: Presentation.Data.TextShape; zeroWidthSpace?: never; breakLine?: never }
  | { text?: never; zeroWidthSpace: true; breakLine?: never }
  | { text?: never; zeroWidthSpace?: never; breakLine: true };

const TextChild = ({ text, zeroWidthSpace, breakLine }: TextChildProps) => {
  const currentPageNumber = useSelector((state) => state.presentation.general.currentSlide);

  const content = useMemo(() => {
    if (zeroWidthSpace) {
      return '\u200B';
    }

    if (breakLine) {
      return <br />;
    }

    if (text?.type === 'tab') {
      return '\t';
    }

    if (
      text.type === 'tx_field' &&
      text?.tx_field_type === 'slidenum' &&
      !/^[0-9]*$/.test(text.content)
    ) {
      return currentPageNumber - 1;
    }
    return text.content;
  }, [text, currentPageNumber]);

  return content;
};

export default TextChild;
