import { useMemo } from 'react';
import cx from 'classnames';

import { useSelector } from '_common/hooks';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';

import { useShapeData } from '../../ShapeData';
import { useTextBody } from '../TextBodyContext';
import { bulletTypeEquals } from '../Bullet/bulletUtils';
import useTextProperties from '../hooks/useTextProperties';
import RunElement from './RunElement';

import Bullet from '../Bullet/Bullet';

import styles from './TextParagraph.module.scss';

type TextParagraphProps = {
  paragraph: Presentation.Data.ParagraphShape;
  paragraphIndex: number;
};

const TextParagraph = ({ paragraph, paragraphIndex }: TextParagraphProps) => {
  const { isThumbnailSlide } = useSlideData();
  const { shape } = useShapeData();
  const { text } = useTextBody();

  const { getParagraphStyle } = useTextProperties();

  const style = getParagraphStyle({ paragraph });

  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);

  const bulletLevels = useMemo(() => {
    if (!text.childNodes?.length) {
      return undefined;
    }

    const levels: Record<number, { parentLevel: number; childLevel: number; index: number }> = {};

    for (let i = 0; i < text.childNodes?.length; i++) {
      const paragraph = text.childNodes[i];

      const childLevel = paragraph.properties.lvl ?? 0;
      const childBullet = paragraph.properties.bullet;
      const startAt = childBullet?.symbol?.startAt ? childBullet.symbol.startAt - 1 : 0;
      const minIndex = startAt + 1;
      let parentLevel = startAt;
      let index = minIndex;

      if (i > 0) {
        const prev = levels[i - 1];

        if (childLevel === 0) {
          parentLevel = 0;
          const prevSameLevel = Object.typedKeys(levels).findLast(
            (level) =>
              levels[level].parentLevel === parentLevel && levels[level].childLevel === childLevel,
          );
          if (prevSameLevel) {
            index = levels[prevSameLevel].index + 1;
          }
        }
        if (childLevel === prev.childLevel) {
          const prevBullet = text.childNodes[i - 1].properties.bullet;

          if (!bulletTypeEquals(childBullet?.symbol, prevBullet?.symbol)) {
            index = minIndex;
          } else {
            index = Math.max(minIndex, prev.index + 1);
          }
          parentLevel = prev.parentLevel;
        } else if (childLevel > prev.childLevel) {
          parentLevel = prev.parentLevel + 1;
          index = minIndex;
        } else if (childLevel < prev.childLevel) {
          parentLevel = prev.parentLevel - 1;

          const prevSameLevel = Object.typedKeys(levels).findLast((level) => {
            return (
              levels[level].parentLevel === parentLevel && levels[level].childLevel === childLevel
            );
          });

          if (prevSameLevel) {
            const prevSameLevelBullet = text.childNodes[prevSameLevel].properties.bullet;
            if (!bulletTypeEquals(childBullet?.symbol, prevSameLevelBullet?.symbol)) {
              index = levels[prevSameLevel].index;
            } else {
              index = levels[prevSameLevel].index + 1;
            }
          }
        }
      }
      levels[i] = { parentLevel, childLevel, index };
    }

    return levels;
  }, [text]);

  return (
    <div
      style={{
        ...style,
        position: 'relative',
      }}
      className={cx(styles.root, { [styles.selected]: selectedShape === shape.id })}
      id={isThumbnailSlide ? `thumbnail-${paragraph.id}` : paragraph.id}
      data-type="p"
    >
      <Bullet
        paragraph={paragraph}
        index={bulletLevels?.[paragraphIndex].index}
        level={bulletLevels?.[paragraphIndex].childLevel}
        listStyle={text.listStyle}
      />
      {/*Some paragraphs dont have children, but they are supposed to have their dimensions defined so we render a &ZeroWidthSpace; as child*/}
      {paragraph.childNodes && !paragraph?.childNodes.length ? (
        <RunElement paragraph={paragraph} zeroWidthSpace />
      ) : (
        paragraph.childNodes?.map((run, index) => (
          <RunElement paragraph={paragraph} run={run} key={index} />
        ))
      )}
    </div>
  );
};

export default TextParagraph;
