import { FC } from 'react';
import * as echarts from 'echarts';

import { useChartColor, useDataLabel } from '../hooks';

import ChartBase from '../ChartBase/ChartBase';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';
import useDataLabelLine from '../hooks/useDataLabelLine';
import { indexedModEffects } from 'Presentation/utils';
import useOutline from '../../useOutline';

type PieChartProps = {
  shape: Presentation.Data.ChartShape;
};

const useSeries = ({ shape }: PieChartProps): echarts.PieSeriesOption[] | undefined => {
  const { color, addUnsupportedElement } = useSlideData();
  const { chartColor } = useChartColor();
  const { dataLabel } = useDataLabel();
  const { dataLabelLine } = useDataLabelLine();
  const { parseOutline } = useOutline();

  if (
    shape.chartSpace.chart.plotArea.chartTypes?.[0].type !== 'pie' &&
    shape.chartSpace.chart.plotArea.chartTypes?.[0].type !== 'doughnut'
  ) {
    return undefined;
  }

  const chartTypeData: Presentation.Data.PieChart | Presentation.Data.DoughnutChart =
    shape.chartSpace.chart.plotArea.chartTypes[0];

  const outlineStyles = (ln: Presentation.Data.Outline | undefined) => {
    const value = parseOutline(ln);
    return {
      borderColor: value.stroke,
      borderWidth: value.strokeWidth,
      borderCap: value.strokeLinecap,
      borderJoin: value.strokeLinejoin,
      borderMiterLimit: value.strokeMiterlimit,
      borderType: value.strokeDasharray?.split(',').map((value) => +value),
    };
  };
  const chartName = chartTypeData?.type.charAt(0).toUpperCase() + chartTypeData?.type.slice(1);
  if (chartTypeData?.firstSliceAng && chartTypeData?.firstSliceAng > 0) {
    //TODO:PRESENTATION:UNSUPPORTED:CHART:PIE/DOUGHNUT:SERIESOPTIONS:ANGLEOFFIRSTSLICE
    addUnsupportedElement(`Chart - ${chartName} - Angle of first slice`);
  }

  return chartTypeData.ser.map((serie) => {
    if (serie.explosion && serie.explosion > 0) {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:PIE/DOUGHNUT:SERIESOPTIONS:(POINT/DOUGHNUT)EXPLOSION
      addUnsupportedElement(
        `Chart - ${chartName} - ${chartName === 'Pie' ? 'Point' : 'Doughnut'} Explosion`,
      );
    }

    const option: echarts.PieSeriesOption = {
      type: 'pie',
      name: serie.tx?.strRef?.strCache?.pt[0].v,
      id: serie.idx,
      itemStyle: {
        //@ts-expect-error CHARTS:LIMITATION doenst support picture
        color:
          serie.properties?.fill?.type === 'none'
            ? 'transparent'
            : chartColor(serie?.properties?.fill),
      },
      silent: true,
      //@ts-expect-error CHARTS:LIMITATION doenst support picture
      data: serie.val?.numRef?.numCache?.pt?.map((numVal, index) => {
        const idx = numVal.idx;
        const elementProperties = serie.dPt[index].properties;
        const name = serie.cat?.strRef?.strCache.pt[index].v;

        const { glow } = indexedModEffects(elementProperties?.effects);

        const cat =
          serie.cat?.strRef?.strCache?.pt?.find((pt) => pt.idx === idx) ??
          serie.cat?.numRef?.numCache?.pt?.find((pt) => pt.idx === idx) ??
          serie.cat?.strLit?.pt?.find((pt) => pt.idx === idx) ??
          serie.cat?.numLit?.pt?.find((pt) => pt.idx === idx);

        return {
          value: Number(numVal.v),
          name,
          itemStyle: {
            color: chartColor(elementProperties?.fill),
            ...outlineStyles(elementProperties?.ln),
            shadowBlur: glow?.rad,
            shadowColor: glow?.color ? `#${color(glow.color).substring(1)}` : '',
          },
          label: dataLabel({
            dLbls: serie.dLbls,
            val: numVal.v,
            cat: cat?.v,
            idx: numVal.idx,
            defaultPosition: 'inside',
            chart: chartTypeData,
          }),
          labelLine: dataLabelLine(serie.dLbls),
        };
      }),
    };

    //Workaround for undefined radius, since undefined radius isn't valid for chart (chart doesn't render)
    if (chartTypeData.type === 'doughnut' && chartTypeData.holeSize) {
      option.radius = [`${(75 * chartTypeData.holeSize) / 100}%`, '75%'];
    }

    return option;
  });
};

const PieChart: FC<PieChartProps> = ({ shape }) => {
  const series = useSeries({ shape });

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        series,
      }}
    />
  );
};

export default PieChart;
