import { useMemo } from 'react';
import { useSlideData } from '../../../SlideData';
import { TEXT_DECORATION_MAP } from 'Presentation/consts';
import useChartColor from './useChartColor';
import { useChartData } from '../ChartData';
import useFormatCode from './useFormatCode';
import useOutline from '../../useOutline';
import useUnsupportedProperties from './useUnsupportedProperties';

const useChartAxis = (shape: Presentation.Data.ChartShape) => {
  const { color, getFontFamily, addUnsupportedElement } = useSlideData();
  const { chartShape } = useChartData();
  const { chartColor } = useChartColor();
  const { formatValue, getDateFormat } = useFormatCode();
  const { parseOutline } = useOutline();
  const { handleUnsupportedShapeEffects, handleUnsupportedText } = useUnsupportedProperties();
  const plotArea = shape.chartSpace.chart.plotArea;

  const outlineYAxisTitle = parseOutline(plotArea.chartAxes[1].title?.properties?.ln);
  const outlineXAxisTitle = parseOutline(plotArea.chartAxes[0].title?.properties?.ln);

  const titleFontFamily = useMemo(() => {
    return getFontFamily({ font: chartShape.chartStyle?.axisTitle?.fontRef });
  }, [chartShape]);

  const valueFontFamily = useMemo(() => {
    return getFontFamily({ font: chartShape.chartStyle?.valueAxis?.fontRef });
  }, [chartShape]);

  const axisTextFill = (fill: Presentation.Data.Common.FillType | undefined) => {
    switch (fill?.type) {
      case 'gradient':
        //TODO:PRESENTATION:UNSUPPORTED:AXIS:FILL:GRADIENT
        addUnsupportedElement('Chart - Text - Gradient Fill');
        return '#000';
      case 'picture':
        if (fill.tile) {
          //TODO:PRESENTATION:UNSUPPORTED:AXIS:FILL:TEXTURE
          addUnsupportedElement('Chart - Text - Texture Fill');
        } else {
          //TODO:PRESENTATION:UNSUPPORTED:AXIS:FILL:PICTURE
          addUnsupportedElement('Chart - Text - Picture Fill');
        }
        return '#000';
      case 'pattern':
        //TODO:PRESENTATION:UNSUPPORTED:AXIS:FILL:PATTERN
        addUnsupportedElement('Chart - Text - Pattern Fill');
        return '#000';
      default:
        return chartColor(fill);
    }
  };

  const axisTextLine = (fill: Presentation.Data.Common.FillType | undefined) => {
    switch (fill?.type) {
      case 'gradient':
        return;
      default:
        return chartColor(fill);
    }
  };

  const axisFill = (fill: Presentation.Data.Common.FillType | undefined) => {
    switch (fill?.type) {
      case 'gradient':
        //TODO:PRESENTATION:UNSUPPORTED:AXIS:FILL:GRADIENT
        addUnsupportedElement('Chart - Axis - Gradient Fill');
        return 'transparent';
      case 'picture':
        if (fill.tile) {
          //TODO:PRESENTATION:UNSUPPORTED:AXIS:FILL:TEXTURE
          addUnsupportedElement('Chart - Axis - Texture Fill');
        } else {
          //TODO:PRESENTATION:UNSUPPORTED:AXIS:FILL:PICTURE
          addUnsupportedElement('Chart - Axis - Picture Fill');
        }
        return 'transparent';
      case 'pattern':
        //TODO:PRESENTATION:UNSUPPORTED:AXIS:FILL:PATTERN
        addUnsupportedElement('Chart - Axis - Pattern Fill');
        return 'transparent';
      default:
        return chartColor(fill);
    }
  };

  const axisLine = (fill: Presentation.Data.Common.FillType | undefined) => {
    switch (fill?.type) {
      case 'gradient':
        //TODO:PRESENTATION:UNSUPPORTED:AXIS:LINE:GRADIENT
        addUnsupportedElement('Chart - Axis - Gradient Line');
        return;
      default:
        return chartColor(fill);
    }
  };

  const axisGridLine = (fill: Presentation.Data.Common.FillType | undefined) => {
    switch (fill?.type) {
      case 'gradient':
        //TODO:PRESENTATION:UNSUPPORTED:AXIS:LINE:GRADIENT
        addUnsupportedElement('Chart - Axis Grid Line - Gradient Line');
        return;
      default:
        return chartColor(fill);
    }
  };

  const axisPosition = (position: 'b' | 'l' | 'r' | 't') => {
    switch (position) {
      case 'b': {
        return 'bottom';
      }
      case 'l': {
        return 'left';
      }
      case 'r': {
        return 'right';
      }
      case 't': {
        return 'top';
      }
    }
  };

  const axisType = (type: 'cat' | 'val' | 'date' | 'ser') => {
    switch (type) {
      //Proccess date format as category, the formatCode hook should proccess the formatting
      case 'date':
      case 'cat': {
        return 'category';
      }
      case 'val': {
        return 'value';
      }
    }
  };

  const nameLocation = (location: 'ctr' | 'b' | 't' | 'dist' | 'just') => {
    switch (location) {
      case 'ctr':
        return 'middle';
      case 'b':
        return 'end';
      case 't':
        return 'start';
      default:
        return 'middle';
    }
  };

  const axisTitleStyles = (
    properties: Presentation.Data.InlineProperties | undefined,
    axis: 'x' | 'y',
  ) => {
    const textColor = axisFill(properties?.fill);
    const textDecorationStyles = properties?.u ? TEXT_DECORATION_MAP[properties.u] : {};
    const axisTitleBorder = (
      outline: Partial<
        Pick<
          React.SVGAttributes<SVGPathElement>,
          | 'stroke'
          | 'strokeDasharray'
          | 'strokeDashoffset'
          | 'strokeLinecap'
          | 'strokeLinejoin'
          | 'strokeOpacity'
          | 'strokeWidth'
          | 'strokeMiterlimit'
        >
      >,
    ) => {
      return {
        borderColor: outline.stroke,
        borderWidth: outline.strokeWidth,
        borderDashOffset: outline.strokeDashoffset,
        //@ts-expect-error
        borderType: outline.strokeDasharray?.split(',').map((value) => +value),
      };
    };

    return {
      fontFamily: getFontFamily({ font: properties?.font }) ?? titleFontFamily,
      fontSize: properties?.size,
      fontWeight: properties?.b ? 'bold' : undefined,
      fontStyle: properties?.i ? 'italic' : undefined,
      //@ts-expect-error
      textBorderColor: properties?.ln?.fill?.color ? color(properties?.ln?.fill?.color) : undefined,
      textBorderWidth: properties?.ln?.w,
      color: textColor,
      ...axisTitleBorder(axis === 'x' ? outlineXAxisTitle : outlineYAxisTitle),
      ...textDecorationStyles,
    };
  };

  const outlineStyles = (outline: Presentation.Data.Outline | undefined) => {
    const values = parseOutline(outline);

    if (outline) {
      return {
        color: values.stroke,
        width: values.strokeWidth,
        cap: values.strokeLinecap,
        type: values.strokeDasharray?.split(',').map((value) => +value),
        join: values.strokeLinejoin,
        miterLimit: values.strokeMiterlimit,
      };
    }
  };

  const outlineBorderStyles = (outlineBorder: Presentation.Data.Outline | undefined) => {
    const values = parseOutline(outlineBorder);
    if (outlineBorder) {
      return {
        textBorderColor: values.stroke,
        textBorderWidth: values.strokeWidth,
        textBorderType: values.strokeDasharray?.split(',').map((value) => +value),
      };
    }
  };

  const defaultAxisValues = (axis: Presentation.Data.ChartAxesProperties, value: 'x' | 'y') => {
    const showAxisLine = axis?.majorGridlines ? true : false;
    const showAxisMinorGridline = axis?.minorGridlines ? true : false;
    const showAxisTick = axis?.majorTickMark !== 'none';
    const showAxisMinorTick = axis?.minorTickMark !== 'none';
    const showAxisLabel = axis?.delete;
    const axisTitle = axis?.title?.rich?.childNodes?.[0]?.childNodes?.map((run) =>
      run.childNodes?.map((text) => text.type === 'text' && text.content),
    );
    const nameRotate = axis?.title?.rich?.bodyPr?.rot ? -axis.title?.rich?.bodyPr?.rot : 0;
    const labelRotate =
      axis?.text?.bodyPr?.rot && axis?.text?.bodyPr?.rot !== -1000 ? -axis?.text?.bodyPr?.rot : 0;
    const location = axis?.text?.bodyPr?.anchor ?? 'ctr';

    const symbol = (type: Presentation.Data.OutlineEnd['type']) => {
      switch (type) {
        case 'oval':
          return 'circle';
        case 'arrow':
          return 'arrow';
        case 'triangle':
          return 'triangle';
        case 'diamond':
          return 'diamond';
        default:
          return 'none';
      }
    };

    const handleSymbolSize = (ln: Presentation.Data.Outline | undefined) => {
      if (ln && ln?.headEnd) {
        const w = ln?.w ?? 1;
        const smValue = w === 1 ? 5 : 2 * w;
        const medValue = w === 1 ? 7 : 3 * w;
        const lgValue1 = w === 1 ? 12 : 4 * w;
        if (ln?.headEnd?.w === 'sm' && ln?.headEnd?.len === 'sm') {
          return [smValue, smValue];
        } else if (ln?.headEnd?.w === 'med' && ln?.headEnd?.len === 'med') {
          return [medValue, medValue];
        } else if (ln?.headEnd?.w === 'lg' && ln?.headEnd?.len === 'lg') {
          return [lgValue1, lgValue1];
        } else if (ln?.headEnd?.w === 'sm' && ln?.headEnd?.len === 'med') {
          return [smValue, medValue];
        } else if (ln?.headEnd?.w === 'sm' && ln?.headEnd?.len === 'lg') {
          return [smValue, lgValue1];
        } else if (ln?.headEnd?.w === 'med' && ln?.headEnd?.len === 'sm') {
          return [medValue, smValue];
        } else if (ln?.headEnd?.w === 'med' && ln?.headEnd?.len === 'lg') {
          return [medValue, lgValue1];
        } else if (ln?.headEnd?.w === 'lg' && ln?.headEnd?.len === 'sm') {
          return [lgValue1, smValue];
        } else if (ln?.headEnd?.w === 'lg' && ln?.headEnd?.len === 'med') {
          return [lgValue1, medValue];
        }
      } else return [];
    };

    const handleLineSymbol = () => {
      if (
        axis.properties?.ln &&
        axis.properties?.ln?.headEnd?.type &&
        axis.properties?.ln?.tailEnd?.type
      ) {
        return [
          symbol(axis.properties?.ln?.headEnd?.type),
          symbol(axis.properties?.ln?.tailEnd?.type),
        ];
      } else if (axis.properties?.ln && axis.properties?.ln?.headEnd?.type) {
        return symbol(axis.properties?.ln?.headEnd?.type);
      } else if (axis.properties?.ln && axis.properties?.ln?.tailEnd?.type) {
        return symbol(axis.properties?.ln.tailEnd?.type);
      } else {
        return 'none';
      }
    };

    const handleLabelInterval = () => {
      const tickSkip = axis?.tickLblSkip;
      if (tickSkip) {
        return tickSkip > 1 ? tickSkip - 1 : tickSkip;
      }
    };

    const formatCode = axis.numFmt.formatCode;

    //TODO:PRESENTATION:UNSUPPORTED:CHART:GRIDLINE:EFFECTS
    handleUnsupportedShapeEffects(axis.majorGridlines?.properties, 'Chart - Grid Line');
    handleUnsupportedShapeEffects(axis.minorGridlines?.properties, 'Chart - Grid Line');

    if (axis.majorTickMark === 'cross') {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:AXIS
      addUnsupportedElement('Chart - Axis Major Tick Mark - Cross');
    }

    if (axis.minorTickMark === 'cross') {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:AXIS
      addUnsupportedElement('Chart - Axis Minor Tick Mark - Cross');
    }

    if (axis.crossesAt) {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:AXIS
      addUnsupportedElement('Chart - Axis Crosses - At Category Number');
    }
    if (axis.dispUnits?.builtInUnit) {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:AXIS
      addUnsupportedElement('Chart - Axis Display Units');
    }
    if (axis.scaling.logBase) {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:AXIS
      addUnsupportedElement('Chart - Axis Logarithmic Scale');
    }

    if (axis.lblOffset && axis.lblOffset !== 100) {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:AXIS
      addUnsupportedElement('Chart - Axis Label - Distance From Axis');
    }

    let unsupportedTickLblPos = '';
    switch (axis.tickLblPos) {
      case 'high':
        unsupportedTickLblPos = 'High';
        break;
      case 'low':
        unsupportedTickLblPos = 'Low';
        break;
      case 'none':
        unsupportedTickLblPos = 'None';
        break;
    }

    if (unsupportedTickLblPos) {
      //TODO:PRESENTATION:UNSUPPORTED:CHART:AXIS
      addUnsupportedElement(`Chart - Axis Label Position - ${unsupportedTickLblPos}`);
    }

    handleUnsupportedShapeEffects(axis.properties);
    handleUnsupportedText(axis.text);
    handleUnsupportedText(axis.title?.text);

    return {
      name: axisTitle?.join(' ') ?? '',
      nameLocation: nameLocation(location),
      nameTextStyle: {
        ...axisTitleStyles(axis?.title?.text?.childNodes?.[0]?.properties?.inlineProperties, value),
        backgroundColor: axisFill(axis?.title?.properties?.fill),
      },
      nameRotate: nameRotate,
      nameGap: 25,
      inverse: axis.scaling.orientation === 'maxMin' ? true : false,
      axisLine: {
        show: axisLine(axis?.properties?.ln?.fill) ? true : false,
        lineStyle: {
          ...outlineStyles(axis?.properties?.ln),
          color: axisLine(axis?.properties?.ln?.fill),
        },
        symbol: handleLineSymbol(),
        symbolSize: handleSymbolSize(axis?.properties?.ln),
        symbolOffset: [0, 2],
        z: value === 'y' ? 20 : 0,
      },
      axisTick: {
        show: showAxisTick,
        inside: axis.majorTickMark === 'in',
        interval: axis.tickMarkSkip,
        lineStyle: {
          ...outlineStyles(axis?.properties?.ln),
        },
      },
      minorTick: {
        show: showAxisMinorTick,
        lineStyle: {
          ...outlineStyles(axis?.properties?.ln),
        },
      },
      axisLabel: {
        show: !showAxisLabel,
        interval: handleLabelInterval(),
        rotate: labelRotate,
        color: axisTextFill(axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.fill),
        width: value === 'x' ? 60 : 10,
        height: axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.size,
        backgroundColor: axisFill(axis?.properties?.fill),
        formatter: (value: string) => {
          if (axis.type === 'cat') {
            return value;
          }
          return formatValue(value, formatCode).content;
        },
        ...outlineBorderStyles(axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln),
        textBorderColor: axisTextLine(
          axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.ln?.fill,
        ),
        fontFamily:
          getFontFamily({
            font: axis?.text?.childNodes?.[0]?.properties?.inlineProperties?.latin?.font,
          }) ?? valueFontFamily,
      },
      splitLine: {
        show: showAxisLine,
        lineStyle: {
          ...outlineStyles(axis?.majorGridlines?.properties?.ln),
          color: axisGridLine(axis?.majorGridlines?.properties?.ln?.fill) ?? '#ccc',
        },
      },
      minorSplitLine: {
        show: showAxisMinorGridline,
        lineStyle: {
          ...outlineStyles(axis?.minorGridlines?.properties?.ln),
          color: axisGridLine(axis?.minorGridlines?.properties?.ln?.fill) ?? '#ccc',
        },
      },
    };
  };

  const xAxis = useMemo<echarts.EChartsOption['xAxis']>(() => {
    const { chartAxes, chartTypes } = plotArea;
    const xAxes = chartAxes.filter((axis) => axis.axPos === 'b' || axis.axPos === 't');
    const axes: echarts.EChartsOption['xAxis'] = [];

    if (!xAxes.length) {
      return undefined;
    }

    xAxes.forEach((axis, i) => {
      const chart = chartTypes?.find((chart) => 'axId' in chart && chart.axId.includes(axis.axId));

      if (!chart) {
        return;
      }

      const type = axisType(axis.type);

      //@ts-expect-error
      const xAxis: echarts.XAXisComponentOption = {
        ...defaultAxisValues(axis, 'x'),
        position: axisPosition(axis.axPos),
        //@ts-expect-error
        type: chart.barDir === 'bar' ? 'value' : type,
        max: axis.scaling.max,
        min: axis.scaling.min,
      };

      if (xAxis?.type === 'category') {
        const data =
          //@ts-expect-error
          chart.ser[0].cat?.strRef?.strCache ?? chart.ser[0].cat?.numRef?.numCache;
        //@ts-expect-error
        xAxis.data = data?.pt?.map((el) => el.v); //@ts-expect-error
        xAxis.boundaryGap = chartAxes[1].crossBetween === 'between' ? true : false;
      }

      axes.push(xAxis);
    });

    return axes;
  }, [plotArea]);

  const yAxis = useMemo<echarts.EChartsOption['yAxis']>(() => {
    /**
     * The min value, max value, and number of intervals between them are defined automatically by powerpoint if the user doesnt set these values manually
     * If some values are set manually and the others not, the ones that arent manually set, will still be defined automatically
     * Powerpoint defines this with an algorithm that tries to find the best values that make the chart readable in the best way
     * Echarts library also does this if we dont override the value of min, max or splitNumber, but in some situations it will have different (but similiar) outputs from powerpoint
     * Whether we have our own algorithm or use the one from echarts, the algorithm will be different from powerpoint's, and the values will have differences any way
     * The consequence of this, is that the plot of the chart might look different in some situations, since the values of the axis might be different
     */
    const { chartAxes, chartTypes } = plotArea;

    const yAxes = chartAxes.filter((axis) => axis?.axPos === 'l' || axis?.axPos === 'r');

    const axes: echarts.EChartsOption['yAxis'] = [];

    if (!yAxes?.length) {
      return undefined;
    }

    yAxes.forEach((axis, i) => {
      const chart = chartTypes?.find((chart) => 'axId' in chart && chart.axId.includes(axis.axId));

      if (!chart) {
        return;
      }

      const formatCode = axis.numFmt.formatCode;
      const dateFormat = getDateFormat({ formatCode });

      const percentageFormat = new RegExp(/(0(.)*(%))/);
      const isPercentage = percentageFormat.test(formatCode);

      const type = axisType(axis.type);

      let defaultMin: number | undefined = undefined;
      let defaultMax: number | undefined = undefined;

      chart.ser.forEach((ser) => {
        const values =
          'val' in ser ? ser.val?.numRef?.numCache ?? ser.val?.strRef?.strCache : undefined;

        values?.pt?.forEach((pt) => {
          const ptValue = parseFloat(pt.v);

          if (!isNaN(ptValue)) {
            if (defaultMin === undefined || ptValue < defaultMin) {
              defaultMin = ptValue;
            }

            if (defaultMax === undefined || ptValue > defaultMax) {
              defaultMax = ptValue;
            }
          }
        });
      });

      const majorUnit =
        axis?.majorUnit != null
          ? isPercentage
            ? axis?.majorUnit * 100
            : axis?.majorUnit
          : isPercentage
          ? 10
          : undefined;

      const max =
        axis.scaling.max != null
          ? isPercentage
            ? axis.scaling.max * 100
            : axis.scaling.max
          : defaultMax != null && dateFormat
          ? defaultMax + 1
          : undefined; //TODO:CHARTS Find a way to know the default max value

      const min =
        axis.scaling.min != null
          ? isPercentage
            ? axis.scaling.min * 100
            : axis.scaling.min
          : defaultMin != null && dateFormat
          ? Math.floor(defaultMin - 1)
          : undefined; //TODO:CHARTS Find a way to know the default min value

      let splitNumber = majorUnit;

      if (majorUnit != null && defaultMax != null && defaultMin != null) {
        /**
         * If the 'majorUnit' property value is not automatic (undefined), then we need to override the automatic value of 'splitNumber' defined by the library
         * To determine the 'splitNumber' we have to make a calculation with the min, max and majorUnit values
         * However, if the the max and/or min are set as auto (undefined), their values will be decided by the echarts library
         * If this happens, we dont know what the values defined by the library are, so we will assume that the min and max are the min and max values of the data
         * By assuming this, in some situations, the splitNumber will not be calculated with the same values powerpoint uses
         * Therefore, the output of the axis will be different from powerpoint. It might happen to be the same in some situations tho
         * If we dont these assumptions, the splitNumber will have to be defined by the library and the axis outputs will be much different
         */

        const range = (max ?? defaultMax) - (min ?? defaultMin);
        splitNumber = Math.round(range / majorUnit);
      }

      const yAxis: echarts.YAXisComponentOption = {
        ...defaultAxisValues(axis, 'y'),
        id: axis.axId,
        position: axisPosition(axis.axPos),
        //@ts-expect-error
        type: chart.barDir === 'bar' ? 'category' : type,
        max: max,
        min: min,
        //@ts-expect-error
        splitNumber: chart.type === 'bubble' ? 8 : splitNumber,
      };

      if (yAxis?.type === 'category') {
        const data =
          //@ts-expect-error
          chart.ser[0].cat?.strRef?.strCache ?? chart.ser[0].cat?.numRef?.numCache;
        //@ts-expect-error
        yAxis.data = data?.pt?.map((el) => el.v); //@ts-expect-error
        yAxis.boundaryGap = chartAxes[1].crossBetween === 'between' ? true : false;
      }

      axes.push(yAxis);
    });

    return axes;
  }, [plotArea]);

  return { xAxis, yAxis };
};

export default useChartAxis;
