import { FC } from 'react';
import CommonShape from './CommonShape';
import GroupShape from './Group/GroupShape';
import TableShape from './Table/TableShape';
import ChartShape from './Chart/ChartShape';
import ShapeFallback from './ShapeFallback';
import { useSlideData } from '../SlideData';

type ShapeProps = {
  shape: Presentation.Data.Shape;
};

const Shape: FC<ShapeProps> = ({ shape }) => {
  const { addUnsupportedElement } = useSlideData();

  switch (shape.type) {
    case 'slidezoom':
      addUnsupportedElement('Slide Zoom');
      //TODO:PRESENTATION:UNSUPPORTED
      return <ShapeFallback fallback={shape.fallback} />;
    case 'model3d':
      addUnsupportedElement('3D models');
      //TODO:PRESENTATION:UNSUPPORTED
      return <ShapeFallback fallback={shape.fallback} />;
    case 'diagram':
      //TODO:PRESENTATION:UNSUPPORTED
      addUnsupportedElement('SmartArt');
      return null;
    case 'webextension':
      addUnsupportedElement('Add-ins');
      //TODO:PRESENTATION:UNSUPPORTED
      return <ShapeFallback fallback={shape.fallback} />;
    case 'oleObj':
      //TODO:PRESENTATION:UNSUPPORTED
      addUnsupportedElement('Object');
      return <ShapeFallback fallback={shape.oleObj.fallback.oleObj.picture} />;
    case 'group_shape':
      return <GroupShape key={shape.id} shape={shape} />;
    case 'table':
      return <TableShape key={shape.id} shape={shape} />;
    case 'chartex':
    case 'chart':
      return <ChartShape key={shape.id} shape={shape} />;
    /**
     * Videos are picture shapes with media[type='video'] in nvProperties
     * Sounds are picture shapes with media[type='audio'] in nvProperties
     */
    case 'picture':
      switch (shape.nvProperties?.media?.type) {
        case 'video':
          addUnsupportedElement('Video');
          break;
        case 'audio':
          addUnsupportedElement('Audio');
          break;
      }
      return <CommonShape key={shape.id} shape={shape} />;
    case 'shape':
    default:
      return <CommonShape key={shape.id} shape={shape} />;
  }
};

export default Shape;
