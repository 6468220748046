import { Logger } from '_common/services';
import { Command } from '../Command';
import { JsonRange, SelectionFixer } from 'Editor/services/_Common/Selection';
import { NodeUtils } from 'Editor/services/DataManager';

type BlockStylesMapper = {
  [style in Editor.Edition.InlineStyles]: {
    getStylePropPath: (blockData: Editor.Data.Node.Data) => Realtime.Core.RealtimePath | undefined;
  };
};

const BLOCK_STYLES_MAPPER: BlockStylesMapper = {
  fontFamily: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'ff'];
      }
      return undefined;
    },
  },
  fontSize: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'fs'];
      }
      return undefined;
    },
  },
  color: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'c'];
      }
      return undefined;
    },
  },
  highlightColor: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'bg'];
      }
      return undefined;
    },
  },
  bold: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'b'];
      }
      return undefined;
    },
  },
  italic: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'i'];
      }
      return undefined;
    },
  },
  underline: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'u'];
      }
      return undefined;
    },
  },
  strikethrough: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'strikethrough'];
      }
      return undefined;
    },
  },
  superscript: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'sps'];
      }
      return undefined;
    },
  },
  subscript: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      if (NodeUtils.isParagraphData(blockData)) {
        return ['properties', 'sbs'];
      }
      return undefined;
    },
  },
  vanish: {
    getStylePropPath: function (blockData: Editor.Data.Node.Data) {
      return ['properties', 'v'];
    },
  },
};

export class StylesCommand extends Command {
  private stylesToApply: Editor.Edition.InlineStylesMap;
  private stylesToRemove: Editor.Edition.InlineStylesMap;
  private options: Editor.Edition.StylesCommandOptions;

  private rangeToApply?: Editor.Selection.JsonRange;

  constructor(
    context: Editor.Edition.Context,
    stylesToApply: Editor.Edition.InlineStylesMap = {},
    stylesToRemove: Editor.Edition.InlineStylesMap = {},
    rangeToApply?: Editor.Selection.JsonRange,
    options: Editor.Edition.StylesCommandOptions = {},
  ) {
    super(context);

    this.stylesToApply = stylesToApply;
    this.stylesToRemove = stylesToRemove;
    this.options = {
      applySelection: true,
      createPatch: true,
      ...options,
    };

    this.rangeToApply = rangeToApply;
  }

  private async removeStyleFromBlock(
    baseModel: Editor.Data.Node.Model,
    blockData: Editor.Data.Node.Data,
    blockPath: Editor.Selection.Path,
    style: Editor.Edition.InlineStyles,
  ) {
    const propPath = BLOCK_STYLES_MAPPER[style].getStylePropPath(blockData);

    if (propPath) {
      await baseModel.delete([...blockPath, ...propPath], {
        source: 'LOCAL_RENDER',
      });
    }
  }

  private async addStyleToBlock<T extends Editor.Edition.InlineStyles>(
    baseModel: Editor.Data.Node.Model,
    blockData: Editor.Data.Node.Data,
    blockPath: Editor.Selection.Path,
    style: T,
    value: Editor.Edition.StylesMap[T],
  ) {
    const propPath = BLOCK_STYLES_MAPPER[style].getStylePropPath(blockData);

    if (propPath) {
      await baseModel.set([...blockPath, ...propPath], value, {
        source: 'LOCAL_RENDER',
      });
    }
  }

  private async applyStylesToBlockData(
    baseModel: Editor.Data.Node.Model,
    blockData: Editor.Data.Node.Data,
    blockPath: Editor.Selection.Path,
  ) {
    if (!this.context.stylesManager) {
      return false;
    }

    const styles = Object.keys(this.stylesToApply) as Editor.Edition.InlineStyles[];

    for (let i = 0; i < styles.length; i++) {
      if (this.context.stylesManager.isAllowedBlockStyle(blockData.type, styles[i])) {
        if (this.context.stylesManager.isInlineExlusiveStyle(styles[i])) {
          // remove exclusive styles
          await this.removeStyleFromBlock(baseModel, blockData, blockPath, styles[i]);
        }

        if (this.context.stylesManager.isInlineSingleStateStyle(styles[i])) {
          // remove style
          await this.removeStyleFromBlock(baseModel, blockData, blockPath, styles[i]);

          if (this.stylesToApply[styles[i]] === true) {
            // add style
            await this.addStyleToBlock(
              baseModel,
              blockData,
              blockPath,
              styles[i],
              this.stylesToApply[styles[i]],
            );
          }
        } else {
          // add style
          await this.addStyleToBlock(
            baseModel,
            blockData,
            blockPath,
            styles[i],
            this.stylesToApply[styles[i]],
          );
        }
      }
    }
  }

  private applyStylesToTextData(
    range: Editor.Selection.JsonRange,
    baseModel: Editor.Data.Node.Model,
    blockData: Editor.Data.Node.Data,
    blockPath: Editor.Selection.Path,
  ) {
    const baseData = baseModel.selectedData();

    if (!this.context.stylesManager || !this.context.contentManipulator || !baseData) {
      return false;
    }

    const stylesApplied = this.context.stylesManager.getStylesAppliedToContent(
      baseData,
      range.getCommonAncestorPath(),
      this.context.stylesManager.STYLES,
    );

    let resultRange: Editor.Selection.JsonRange = range;

    const styles = Object.keys(this.stylesToApply) as Editor.Edition.InlineStyles[];

    for (let i = 0; i < styles.length; i++) {
      if (this.context.stylesManager.isAllowedInlineStyle(blockData.type, styles[i])) {
        if (this.context.stylesManager.isInlineExlusiveStyle(styles[i])) {
          // exlusive styles
          const styleToRemove = this.context.stylesManager.INLINE_EXCLUSIVE_STYLES[styles[i]];
          if (styleToRemove) {
            // undo to range
            resultRange = this.context.contentManipulator.removeStyle(
              baseModel,
              resultRange,
              styleToRemove,
            );
          }

          if (resultRange) {
            resultRange = this.context.contentManipulator.applyStyle(
              baseModel,
              resultRange,
              styles[i],
              this.stylesToApply[styles[i]],
            );
          }
        } else if (this.context.stylesManager.isInlineBooleanStyle(styles[i])) {
          // boolean styles
          let valueToApply: boolean =
            this.stylesToApply[styles[i]] === true || this.stylesToApply[styles[i]] === 'true';
          let valueToRemove: boolean = !valueToApply;

          // undo to range oposite value
          resultRange = this.context.contentManipulator.removeStyle(
            baseModel,
            resultRange,
            styles[i],
            valueToRemove,
          );

          if (stylesApplied[styles[i]] !== valueToApply) {
            // apply to range
            resultRange = this.context.contentManipulator.applyStyle(
              baseModel,
              resultRange,
              styles[i],
              valueToApply,
            );
          }
        } else if (this.context.stylesManager.isInlineSingleStateStyle(styles[i])) {
          // single state styles

          if (this.stylesToApply[styles[i]] === true) {
            // apply to range
            resultRange = this.context.contentManipulator.applyStyle(
              baseModel,
              resultRange,
              styles[i],
              this.stylesToApply[styles[i]],
            );
          } else {
            // undo to range
            resultRange = this.context.contentManipulator.removeStyle(
              baseModel,
              resultRange,
              styles[i],
            );
          }
        } else {
          // remove from range
          resultRange = this.context.contentManipulator.removeStyle(
            baseModel,
            resultRange,
            styles[i],
            // this.stylesToApply[styles[i]],
          );

          // apply to range
          resultRange = this.context.contentManipulator.applyStyle(
            baseModel,
            resultRange,
            styles[i],
            this.stylesToApply[styles[i]],
          );
        }
      }

      range.updateRangePositions(resultRange.start, resultRange.end);
    }
  }

  private async applyStylesToRange(ctx: Editor.Edition.ActionContext) {
    if (!this.context.DataManager || !this.context.stylesManager) {
      return false;
    }

    // normalize text selection
    SelectionFixer.normalizeTextSelection(
      ctx.range,
      {
        // suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        forceTextAsWrap: true,
      },
      this.context.DataManager,
    );

    const blockRangesData = JsonRange.splitRangeByTypes(
      this.context.DataManager,
      ctx.range,
      [...this.context.stylesManager.STYLABLE_BLOCKS],
      {
        onlyContainerLevel: true,
        useSelectedCells: true,
      },
    );

    for (let i = 0; i < blockRangesData.length; i++) {
      const range = blockRangesData[i].range;
      const blockData = blockRangesData[i].filteredData;
      const blockPath = blockRangesData[i].filteredDataPath;

      const baseModel = this.context.DataManager.nodes.getNodeModelById(range.start.b);

      // check if element is editable
      if (!baseModel || !this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
        continue;
      }

      let subStartPath: Editor.Selection.Path | undefined;
      if (range.start.p.length >= blockPath.length) {
        subStartPath = range.start.p.slice(blockPath.length);
      }

      let subEndPath: Editor.Selection.Path | undefined;
      if (range.end.p.length >= blockPath.length) {
        subEndPath = range.end.p.slice(blockPath.length);
      }

      if (subStartPath && subEndPath) {
        if (NodeUtils.isBlockTextData(blockData)) {
          if (
            NodeUtils.isPathAtContentStart(blockData, subStartPath) &&
            NodeUtils.isPathAtContentEnd(blockData, subEndPath)
          ) {
            await this.applyStylesToBlockData(baseModel, blockData, blockPath);
          }
          this.applyStylesToTextData(range, baseModel, blockData, blockPath);
        } else {
          await this.applyStylesToBlockData(baseModel, blockData, blockPath);
        }
      } else {
        // something is wrong
        Logger.captureException('ERROR: Invalid paths apply styles!!!');
      }

      // update range positions
      if (i === 0) {
        ctx.range.updateStartPosition(range.start);
      }

      if (i === blockRangesData.length - 1) {
        ctx.range.updateEndPosition(range.end);
      }
    }
  }

  private async removeStylesFromBlockData(
    baseModel: Editor.Data.Node.Model,
    blockData: Editor.Data.Node.Data,
    blockPath: Editor.Selection.Path,
  ) {
    if (!this.context.stylesManager) {
      return false;
    }

    const styles = Object.keys(this.stylesToRemove) as Editor.Edition.InlineStyles[];

    for (let i = 0; i < styles.length; i++) {
      if (this.context.stylesManager.isAllowedBlockStyle(blockData.type, styles[i])) {
        await this.removeStyleFromBlock(baseModel, blockData, blockPath, styles[i]);
      }
    }
  }

  private removeStylesFromTextData(
    range: Editor.Selection.JsonRange,
    baseModel: Editor.Data.Node.Model,
    blockData: Editor.Data.Node.Data,
  ) {
    const baseData = baseModel.selectedData();

    if (!this.context.stylesManager || !this.context.contentManipulator || !baseData) {
      return false;
    }

    let resultRange: Editor.Selection.JsonRange | undefined = range;

    const styles = Object.keys(this.stylesToRemove) as Editor.Edition.InlineStyles[];

    for (let i = 0; i < styles.length; i++) {
      if (this.context.stylesManager.isAllowedInlineStyle(blockData.type, styles[i])) {
        resultRange = this.context.contentManipulator.removeStyle(
          baseModel,
          resultRange,
          styles[i],
          this.stylesToRemove[styles[i]],
        );
      }

      range.updateRangePositions(resultRange.start, resultRange.end);
    }
  }

  private async removeStylesFromRange(ctx: Editor.Edition.ActionContext) {
    if (!this.context.DataManager || !this.context.stylesManager) {
      return false;
    }

    // normalize text selection
    SelectionFixer.normalizeTextSelection(
      ctx.range,
      {
        // suggestionMode: this.context.editionMode === 'SUGGESTIONS',
        forceTextAsWrap: true,
      },
      this.context.DataManager,
    );

    const blockRangesData = JsonRange.splitRangeByTypes(
      this.context.DataManager,
      ctx.range,
      [...this.context.stylesManager.STYLABLE_BLOCKS],
      {
        onlyContainerLevel: true,
        useSelectedCells: true,
      },
    );

    for (let i = 0; i < blockRangesData.length; i++) {
      const range = blockRangesData[i].range;
      const blockData = blockRangesData[i].filteredData;
      const blockPath = blockRangesData[i].filteredDataPath;

      const baseModel = this.context.DataManager.nodes.getNodeModelById(range.start.b);

      // check if element is editable
      if (!baseModel || !this.context.DataManager.nodes.isNodeEditable(baseModel.id)) {
        continue;
      }

      let subStartPath: Editor.Selection.Path | undefined;
      if (range.start.p.length >= blockPath.length) {
        subStartPath = range.start.p.slice(blockPath.length);
      }

      let subEndPath: Editor.Selection.Path | undefined;
      if (range.end.p.length >= blockPath.length) {
        subEndPath = range.end.p.slice(blockPath.length);
      }

      if (subStartPath && subEndPath) {
        if (NodeUtils.isBlockTextData(blockData)) {
          if (
            NodeUtils.isPathAtContentStart(blockData, subStartPath) &&
            NodeUtils.isPathAtContentEnd(blockData, subEndPath)
          ) {
            await this.removeStylesFromBlockData(baseModel, blockData, blockPath);
          }
          this.removeStylesFromTextData(range, baseModel, blockData);
        } else {
          await this.removeStylesFromBlockData(baseModel, blockData, blockPath);
        }
      } else {
        // something is wrong
        Logger.captureException('ERROR: Invalid paths apply styles!!!');
      }

      // update range positions
      if (i === 0) {
        ctx.range.updateStartPosition(range.start);
      }

      if (i === blockRangesData.length - 1) {
        ctx.range.updateEndPosition(range.end);
      }
    }
  }

  protected async handleExec(...args: any[]) {
    if (this.context.debug) {
      Logger.trace('ApplyStyleCommand exec', this);
    }

    this.buildActionContext(this.rangeToApply);

    if (!this.actionContext) {
      throw new Error('Context is not defined');
    }

    // if (!this.actionContext.range.collapsed) {
    if (Object.keys(this.stylesToRemove).length > 0) {
      await this.removeStylesFromRange(this.actionContext);
    }

    if (Object.keys(this.stylesToApply).length > 0) {
      await this.applyStylesToRange(this.actionContext);
    }

    if (this.options.cellSelection || this.options.collapseSelection === 'START') {
      this.actionContext.range.collapseToStart();
    }
    if (this.options.collapseSelection === 'END') {
      this.actionContext.range.collapseToEnd();
    }

    if (this.options.applySelection) {
      this.applySelection();
    }

    // force selection changed to update styles values
    // this.context.VisualizerManager?.selection.triggerSelectionChanged();

    if (this.options.createPatch) {
      this.createPatch();
    }
    // }
  }
}
