import { FC } from 'react';
import * as echarts from 'echarts';

import ChartBase from '../ChartBase/ChartBase';
import useDataLabel from '../hooks/useDataLabel';
import {
  useChartAxis,
  useChartColor,
  useChartOutline,
  useChartPlotArea,
  useStacked100,
} from '../hooks';
import useChartSVG from '../useChartSVG';
import { useSlideData } from 'Presentation/Slides/Slide/SlideData';

type LineChartProps = {
  shape: Presentation.Data.ChartShape;
};

const LIB_SYMBOLS: Partial<Record<Presentation.Data.Marker['symbol'], string | null>> = {
  circle: 'circle',
  square: 'rect',
  diamond: 'diamond',
  triangle: 'triangle',
  none: null,
};

const useSeries = ({
  shape,
}: {
  shape: Presentation.Data.ChartShape;
}): echarts.LineSeriesOption[] | undefined => {
  const { chartColor } = useChartColor();
  const { chartOutline } = useChartOutline();
  const { dataLabel } = useDataLabel();
  const { stacked100 } = useStacked100();
  const { markerSymbol } = useChartSVG();
  const { addUnsupportedElement } = useSlideData();

  if (shape.chartSpace.chart.plotArea.chartTypes?.[0].type !== 'line') {
    return undefined;
  }

  const chartTypeData: Presentation.Data.LineChart = shape.chartSpace.chart.plotArea.chartTypes[0];

  const seriesInfo = chartTypeData.ser.map((el) => {
    if (el.val?.numRef?.numCache?.pt) {
      return el.val?.numRef?.numCache?.pt?.map((value) => +value.v * 10);
    }
    return [0];
  });

  const stack = chartTypeData.grouping === 'stacked';
  const stack100Percentage = chartTypeData.grouping === 'percentStacked';
  const percentageData = stacked100(seriesInfo);

  return chartTypeData.ser.map((serie: Presentation.Data.LineSer, i) => {
    const { marker } = serie as unknown as Presentation.Data.LineSer;

    if (serie.smooth) {
      addUnsupportedElement('Chart - Line - Smooth Line');
    }

    const lineOutline = chartOutline(serie.properties?.ln);

    const data: echarts.LineSeriesOption = {
      type: 'line',
      cursor: 'default',
      silent: true,
      lineStyle: {
        //@ts-expect-error CHARTS:LIMITATION color doesnt support picture
        color: lineOutline?.borderColor,
        cap: lineOutline?.borderCap,
        join: lineOutline?.borderJoin,
        miterLimit: lineOutline?.borderMiterLimit,
        type: lineOutline?.borderType,
        width: lineOutline?.borderWidth,
      },
      stack: stack || stack100Percentage ? 'stack' : undefined,
      data: serie.val?.numRef?.numCache.pt.map((numVal, j) => {
        const idx = numVal.idx;
        let itemStyle = undefined;

        if (marker?.properties) {
          const outline = chartOutline(marker.properties.ln);
          itemStyle = {
            ...outline,
            color: chartColor(marker?.properties?.fill),
          };
        }

        let symbolBase64: string | undefined = undefined;
        if (marker?.symbol) {
          if (!LIB_SYMBOLS[marker?.symbol]) {
            const symbolSVG = markerSymbol({
              symbol: marker?.symbol,
              properties: marker?.properties,
              height: marker?.size ?? 0,
              width: marker?.size ?? 0,
            });

            if (symbolSVG) {
              symbolBase64 = window.btoa(symbolSVG);
            }
          }
        }

        const cat =
          serie.cat?.strRef?.strCache?.pt?.find((pt) => pt.idx === idx) ??
          serie.cat?.numRef?.numCache?.pt?.find((pt) => pt.idx === idx) ??
          serie.cat?.strLit?.pt?.find((pt) => pt.idx === idx) ??
          serie.cat?.numLit?.pt?.find((pt) => pt.idx === idx);

        const label = dataLabel({
          dLbls: serie.dLbls,
          val: stack100Percentage ? `${percentageData[i][j]}` : numVal.v,
          cat: cat?.v,
          idx,
          defaultPosition: 'top',
          chart: chartTypeData,
        });

        //@ts-expect-error "symbol" cant be null, but this is a hack to display data labels without symbol.
        const data: Exclude<echarts.LineSeriesOption['data'], undefined>[number] = {
          value: numVal.v,
          symbol: marker?.symbol
            ? symbolBase64
              ? `image://data:image/svg+xml;base64,${symbolBase64}`
              : LIB_SYMBOLS[marker.symbol]
            : null,
          symbolSize: marker?.size ?? 0,
          itemStyle,
          label,
        };

        return data;
      }),
    };
    return data;
  });
};

const LineChart: FC<LineChartProps> = ({ shape }) => {
  const axis = useChartAxis(shape);
  const grid = useChartPlotArea(shape.chartSpace.chart.plotArea);
  const series = useSeries({ shape });

  return (
    <ChartBase
      shape={shape}
      chartOptions={{
        //@ts-expect-error echarts types
        grid: {
          ...grid,
        },
        xAxis: axis.xAxis,
        yAxis: axis.yAxis,
        series,
      }}
    />
  );
};

export default LineChart;
