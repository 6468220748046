import { path } from 'd3';
import { useMemo } from 'react';
import {
  generateStarAndBannerPath,
  generateFlowchartPath,
  generateActionButtonPath,
  generateBlockArrowPath,
  generateCalloutPath,
  generateBasicPath,
  generateRectanglePath,
  generateEquationPath,
  generateLinePath,
} from './Preset';

export const parseGeometry = (
  geometry: Presentation.Shape.Geometry,
  size: Presentation.Data.Common.Size | null,
  position: Presentation.Data.Common.Position | null,
  properties?: Presentation.Data.ShapeProperties | undefined,
): Presentation.Data.ParsedGeometry => {
  const parseArrayAvLst = (
    avLst: {
      fmla: string;
      name: string;
    }[],
  ) => {
    return avLst.reduce<Record<string, string>>((total, current) => {
      total[current.name] = current.fmla.substring(4);

      return total;
    }, {});
  };

  const avLstIndexed = Array.isArray(geometry.avLst)
    ? parseArrayAvLst(geometry.avLst)
    : geometry.avLst;

  //#region Custom shapes
  if (geometry?.type === 'custom' && position) {
    const parsedGeometry: Presentation.Data.ParsedGeometry = {
      paths: geometry.pathLst.map((p) => {
        const d = path();
        const pw = p?.w ?? 0;
        const ph = p?.h ?? 0;
        const width = size?.width ?? 0;
        const height = size?.height ?? 0;
        const stroke = p.strk === false ? 'false' : 'true'; //.strk default is true
        const fillModifier = p.fill === 'none' ? undefined : p.fill ?? 'norm';
        const fill = p.fill === 'none' ? 'none' : undefined;

        for (let i = 0; i < p.actions.length; i++) {
          const action = p.actions[i];

          const handleXValue = (x: number) => {
            if (pw < 2) {
              if (width * x > width || x > width) {
                return width;
              } else if (x !== 0 && x < 0.15 && pw < 0.15) {
                return (x * 9525) / 6.75;
              } else {
                return width * x;
              }
            } else {
              if (pw > width && pw - width > 15) {
                return x / 2;
              } else {
                return x;
              }
            }
          };

          const handleYValue = (y: number) => {
            if (ph < 2) {
              if (height * y > height || y > height) {
                return height;
              } else if (y !== 0 && y < 0.15 && ph < 0.15) {
                return (y * 9525) / 5.8;
              } else {
                return height * y;
              }
            } else {
              if (ph > height && ph - height > 16) {
                return y / 2;
              } else {
                return y;
              }
            }
          };

          switch (action.type) {
            case 'mt': {
              d.moveTo(handleXValue(action.pt.x), handleYValue(action.pt.y));
              break;
            }
            case 'lt': {
              d.lineTo(handleXValue(action.pt.x), handleYValue(action.pt.y));
              break;
            }
            case 'arct': {
              const { hR, wR, stAng, swAng } = action;

              const startAngleRad = (stAng * Math.PI) / 180;
              const endAngleRad = ((stAng + swAng) * Math.PI) / 180;

              // Calculate start and end points of the arc
              const startX = hR * Math.cos(startAngleRad);
              const startY = wR * Math.sin(startAngleRad);
              const endX = hR * Math.cos(endAngleRad);
              const endY = wR * Math.sin(endAngleRad);

              // Calculate the center of the arc
              const centerX = (startX + endX) / 2;
              const centerY = (startY + endY) / 2;

              //TODO: This is possibly wrong, check if stAng and swAng should be radian angles instead of degree
              d.arc(centerX, centerY, hR, stAng, stAng + swAng, stAng + swAng < 0);

              break;
            }
            case 'cbezt': {
              const [cp1, cp2, ep] = action.pts;
              d.bezierCurveTo(
                handleXValue(cp1.x),
                handleYValue(cp1.y),
                handleXValue(cp2.x),
                handleYValue(cp2.y),
                handleXValue(ep.x),
                handleYValue(ep.y),
              );
              break;
            }
            case 'qbezt': {
              const [cp, ep] = action.pts;
              const x1 = cp.x;
              const y1 = cp.y;
              const x2 = ep.x;
              const y2 = ep.y;
              d.quadraticCurveTo(x1, y1, x2, y2);
              break;
            }
            case 'cls':
              d.closePath();
          }
        }
        //Stroke needs to be a string value
        return { d: d.toString(), stroke, fillModifier, fill };
      }),
    };

    return parsedGeometry;
  }
  //#endregion

  if (geometry.type === 'prst' && position && size) {
    const type = geometry?.prst ?? 'rect';

    switch (type) {
      //#region Line shapes
      case 'line':
      case 'straightConnector1':
      case 'bentConnector3':
      case 'curvedConnector3':
        return generateLinePath({
          size,
          adjst: avLstIndexed,
          type,
          tailEnd: !!properties?.ln?.tailEnd?.type,
          headEnd: !!properties?.ln?.headEnd?.type,
        });
      //#endregion

      //#region Rectangle shapes
      case 'rect':
      case 'roundRect':
      case 'snip1Rect':
      case 'snip2SameRect':
      case 'snip2DiagRect':
      case 'snipRoundRect':
      case 'round1Rect':
      case 'round2SameRect':
      case 'round2DiagRect':
        return generateRectanglePath({ size, adjst: avLstIndexed, type });
      //#endregion

      //#region Basic shapes
      case 'ellipse':
      case 'triangle':
      case 'rtTriangle':
      case 'parallelogram':
      case 'trapezoid':
      case 'diamond':
      case 'pentagon':
      case 'hexagon':
      case 'heptagon':
      case 'octagon':
      case 'decagon':
      case 'dodecagon':
      case 'pie':
      case 'chord':
      case 'teardrop':
      case 'frame':
      case 'halfFrame':
      case 'corner':
      case 'diagStripe':
      case 'plus':
      case 'plaque':
      case 'can':
      case 'cube':
      case 'bevel':
      case 'donut':
      case 'noSmoking':
      case 'blockArc':
      case 'foldedCorner':
      case 'smileyFace':
      case 'heart':
      case 'lightningBolt':
      case 'sun':
      case 'moon':
      case 'cloud':
      case 'arc':
      case 'bracketPair':
      case 'bracePair':
      case 'leftBracket':
      case 'rightBracket':
      case 'leftBrace':
      case 'rightBrace':
        return generateBasicPath({ size, adjst: avLstIndexed, type });
      //#endregion

      //#region BlockArrow shapes
      case 'rightArrow':
      case 'leftArrow':
      case 'upArrow':
      case 'downArrow':
      case 'leftRightArrow':
      case 'upDownArrow':
      case 'quadArrow':
      case 'leftRightUpArrow':
      case 'bentArrow':
      case 'uturnArrow':
      case 'leftUpArrow':
      case 'bentUpArrow':
      case 'curvedRightArrow':
      case 'curvedLeftArrow':
      case 'curvedUpArrow':
      case 'curvedDownArrow':
      case 'stripedRightArrow':
      case 'notchedRightArrow':
      case 'homePlate':
      case 'chevron':
      case 'rightArrowCallout':
      case 'downArrowCallout':
      case 'leftArrowCallout':
      case 'upArrowCallout':
      case 'leftRightArrowCallout':
      case 'quadArrowCallout':
      case 'circularArrow':
        return generateBlockArrowPath({ size, type, adjst: avLstIndexed });
      //#endregion

      //#region Equation shapes
      case 'mathPlus':
      case 'mathMinus':
      case 'mathMultiply':
      case 'mathDivide':
      case 'mathEqual':
      case 'mathNotEqual':
        return generateEquationPath({ size, type, adjst: avLstIndexed });
      //#endregion

      //#region Flowchart shapes
      case 'flowChartProcess':
      case 'flowChartAlternateProcess':
      case 'flowChartDecision':
      case 'flowChartInputOutput':
      case 'flowChartPredefinedProcess':
      case 'flowChartInternalStorage':
      case 'flowChartDocument':
      case 'flowChartMultidocument':
      case 'flowChartTerminator':
      case 'flowChartPreparation':
      case 'flowChartManualInput':
      case 'flowChartManualOperation':
      case 'flowChartConnector':
      case 'flowChartOffpageConnector':
      case 'flowChartPunchedCard':
      case 'flowChartPunchedTape':
      case 'flowChartSummingJunction':
      case 'flowChartOr':
      case 'flowChartCollate':
      case 'flowChartSort':
      case 'flowChartExtract':
      case 'flowChartMerge':
      case 'flowChartOnlineStorage':
      case 'flowChartDelay':
      case 'flowChartMagneticTape':
      case 'flowChartMagneticDisk':
      case 'flowChartMagneticDrum':
      case 'flowChartDisplay':
        return generateFlowchartPath({ size, type });
      //#endregion

      //#region Star and Banner shapes
      case 'irregularSeal1':
      case 'irregularSeal2':
      case 'star4':
      case 'star5':
      case 'star6':
      case 'star7':
      case 'star8':
      case 'star10':
      case 'star12':
      case 'star16':
      case 'star24':
      case 'star32':
      case 'ribbon2':
      case 'ribbon':
      case 'ellipseRibbon2':
      case 'ellipseRibbon':
      case 'verticalScroll':
      case 'horizontalScroll':
      case 'wave':
      case 'doubleWave':
        return generateStarAndBannerPath({ size, adjst: avLstIndexed, type });
      //#endregion

      //#region Callout shapes
      case 'wedgeRectCallout':
      case 'wedgeRoundRectCallout':
      case 'wedgeEllipseCallout':
      case 'cloudCallout':
      case 'borderCallout1':
      case 'borderCallout2':
      case 'borderCallout3':
      case 'accentCallout1':
      case 'accentCallout2':
      case 'accentCallout3':
      case 'callout1':
      case 'callout2':
      case 'callout3':
      case 'accentBorderCallout1':
      case 'accentBorderCallout2':
      case 'accentBorderCallout3':
        return generateCalloutPath({ size, type, adjst: avLstIndexed });
      //#endregion

      //#region ActionButton shapes
      case 'actionButtonBackPrevious':
      case 'actionButtonForwardNext':
      case 'actionButtonBeginning':
      case 'actionButtonEnd':
      case 'actionButtonHome':
      case 'actionButtonInformation':
      case 'actionButtonReturn':
      case 'actionButtonMovie':
      case 'actionButtonDocument':
      case 'actionButtonSound':
      case 'actionButtonHelp':
      case 'actionButtonBlank':
        return generateActionButtonPath({ size, type });
      //#endregion

      /**
       * The following shapes are not available from the shape menu in PowerPoint
       * however, they are mentioned in the preset shape definitions
       * Source: https://github.com/LibreOffice/core/blob/master/oox/source/drawingml/customshapes/presetShapeDefinitions.xml
       */
      case 'bentConnector2':
      case 'bentConnector4':
      case 'bentConnector5':
      case 'chartPlus':
      case 'chartStar':
      case 'chartX':
      case 'cornerTabs':
      case 'curvedConnector2':
      case 'curvedConnector4':
      case 'curvedConnector5':
      case 'flowChartOfflineStorage': // Unsure what this shape is (Flowchart: 29th shape and powerpoint only has 28)
      case 'funnel':
      case 'gear6':
      case 'gear9':
      case 'leftCircularArrow':
      case 'leftRightCircularArrow':
      case 'leftRightRibbon':
      case 'lineInv':
      case 'nonIsoscelesTrapezoid':
      case 'pieWedge':
      case 'plaqueTabs':
      case 'squareTabs':
      case 'swooshArrow':
      case 'upDownArrowCallout': {
        console.warn('Shape preset geometry', type, 'TBI');
        const d = path();
        d.rect(position.left, position.top, size.width, size.height);
        return { paths: [{ d: d.toString() }] };
      }
      default:
        console.warn('Shape preset geometry', type, 'TBI');
    }
  }
  return { paths: [] };
};

const useGeometry = (
  geometry: Presentation.Shape.Geometry | undefined,
  size: Presentation.Data.Common.Size,
  position: Presentation.Data.Common.Position,
  properties?: Presentation.Data.ShapeProperties | undefined,
) => {
  return useMemo(() => {
    return parseGeometry(geometry ?? { type: 'prst', prst: 'rect' }, size, position, properties);
  }, [geometry, size, position]);
};

export default useGeometry;
