import { merge } from 'lodash';
import { useSlideId } from 'Presentation/hooks';
import { usePresentationData } from 'Presentation/PresentationData';
import { useNotesMaster, useNotesSlide, useSlideSync } from 'Presentation/SyncStore';
import { cloneObject } from 'Presentation/utils';
import { createContext, memo, ReactNode, useCallback, useContext, useMemo } from 'react';

type NoteContextValue = {
  notesSlide: Presentation.Data.NotesSlide;
  notesMaster: Presentation.Data.NotesMaster;
};

const NoteContext = createContext<NoteContextValue | undefined>(undefined);

type NoteDataProviderProps = {
  children: ReactNode;
};

const NoteDataProvider = ({ children }: NoteDataProviderProps) => {
  const slideId = useSlideId();
  const { structure } = usePresentationData();
  const slide = useSlideSync(slideId);
  const notesSlide = useNotesSlide(slide?.noteSld);
  const notesMaster = useNotesMaster(notesSlide?.notesMaster);

  const mergeListStyle = useCallback(
    (destination: Parameters<typeof merge>[0], source: Parameters<typeof merge>[1]) => {
      Object.typedKeys(source).forEach((styleIdx) => {
        merge(source[styleIdx], destination[styleIdx]);

        //Make a shallow copy instead of a deep copy
        if (source[styleIdx]?.bullet) {
          destination[styleIdx].bullet = {
            ...destination[styleIdx].bullet,
            ...source[styleIdx].bullet,
          };
        } else {
          destination[styleIdx].bullet = undefined; //not sure if 100% correct (without this footer has extra bullets)
        }
      });

      return destination;
    },
    [],
  );

  const shapes = useMemo(() => {
    if (notesSlide && notesMaster) {
      /**
       * Remove the last shape from the notes slide, which is the notes placeholder
       * and filter out any shapes that don't have any text
       */
      return notesSlide.spTree.shapes
        .slice(0, notesSlide.spTree.shapes.length - 1)
        .filter((slideShape) => slideShape.text?.childNodes?.[0].childNodes?.length)
        .map((slideShape) => {
          let properties: Presentation.Data.ShapeProperties = {};
          let listStyle: Presentation.Data.ListStyles = merge({}, structure.txStyles);

          let bodyPr: Presentation.Data.TextBodyProperties = {};
          const ph = slideShape.nvProperties?.ph;

          if (ph) {
            let masterShape: Presentation.Data.Shape | undefined = undefined;

            if (ph.type && ph.idx) {
              masterShape = notesMaster.spTree.shapes.find(
                (shape) =>
                  shape.nvProperties?.ph?.type === ph.type &&
                  shape.nvProperties?.ph?.idx === ph.idx,
              );

              if (!masterShape) {
                masterShape = notesMaster.spTree.shapes.find(
                  (shape) => shape.nvProperties?.ph?.idx === ph.idx,
                );
                if (!masterShape) {
                  masterShape = notesMaster.spTree.shapes.find(
                    (shape) => shape.nvProperties?.ph?.type === ph.type,
                  );
                }
              }
            } else if (ph.idx) {
              masterShape = notesMaster.spTree.shapes.find(
                (shape) => shape.nvProperties?.ph?.idx === ph.idx,
              );
            } else if (ph.type) {
              masterShape = notesMaster.spTree.shapes.find(
                (shape) => shape.nvProperties?.ph?.type === ph.type,
              );
            }

            if (masterShape) {
              properties = merge(masterShape.properties, cloneObject(properties));
              listStyle = mergeListStyle(cloneObject(listStyle), masterShape.text?.listStyle);
              bodyPr = merge(cloneObject(bodyPr), masterShape.text?.bodyPr);
            }
          }
          properties = merge(cloneObject(properties), slideShape.properties);
          listStyle = merge(cloneObject(listStyle), slideShape.text?.listStyle);
          bodyPr = merge(cloneObject(bodyPr), slideShape.text?.bodyPr);

          const sanitizedTextNodes = slideShape.text?.childNodes?.map((textNode) => {
            const { cs, ea, latin, fill, highlight, size, ...allowedParaProperties } =
              textNode.endParaProperties ?? {};

            const sanitizedInnerNodes = textNode.childNodes?.map((innerNode) => {
              if (innerNode.properties) {
                const { cs, ea, latin, fill, highlight, size, ...allowedProperties } =
                  innerNode.properties;
                return { ...innerNode, properties: { ...allowedProperties, size: 12 } };
              }

              return { ...innerNode };
            });

            return {
              ...textNode,
              childNodes: sanitizedInnerNodes,
              endParaProperties: allowedParaProperties,
            };
          });

          return {
            ...slideShape,
            properties,
            text: slideShape.text
              ? { ...slideShape.text, listStyle, bodyPr, childNodes: sanitizedTextNodes }
              : undefined,
          };
        });
    }
    return [];
  }, [slideId, slide, notesSlide, notesMaster, structure]);

  if (notesSlide && notesMaster) {
    return (
      <NoteContext.Provider
        value={{
          notesSlide: { ...notesSlide, spTree: { ...notesSlide.spTree, shapes } },
          notesMaster,
        }}
      >
        {children}
      </NoteContext.Provider>
    );
  }
  return null;
};

export const useNoteData = () => {
  const context = useContext(NoteContext);
  if (context === undefined) {
    throw new Error('useNoteData can only be used in a NoteData');
  }
  return context;
};

export default memo(NoteDataProvider);
